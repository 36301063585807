import React, { useState, useEffect } from "react";
import FormModal from "../../FormModal";
import { Form } from "react-bootstrap";
import Button from "../../Form/Button";
import Signature from "../../Signature";

const ViewSignModal = ({ show, handleClose, acknowledgedShow, eSign, value }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ackShow, setAckShow] = useState(true);
    const [signObject, setSignObject] = useState();
    const [valueObject, setValueObject] = useState(false);

    useEffect(() => {
        if (value !== true) {
            setSignObject(value);
            setValueObject(value);
        }
    }, [value]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (signObject === undefined) {
            setSignObject(eSign)
        }
    }, [signObject]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (event) => {
        event.preventDefault();
        acknowledgedShow(signObject)
        handleClose();
    };

    useEffect(() => {
        if (eSign !== null && eSign !== '' && eSign !== undefined) {
            setAckShow(false)
        }
    }, [eSign]);



    const acknowledgeShow = (data) => {
        setAckShow(data)
    }

    const signatureObj = (data) => {
        if (data?.drawSign !== '' || data?.uploadSign !== '') {
            if (data?.drawSign !== '') {
                setSignObject(data?.drawSign)
            } else {
                setSignObject(data?.uploadSign[0])
            }
        } else {
            setAckShow(true)
        }
    }
    return (
        <>

            <FormModal heading={'Signature'} show={show} onClose={handleClose} size="lg" backdrop="static" modalClassName="view_task_modal view-hand-book-modal">

                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form mt-3">
                    <Signature className="mb-3" acknowledgeShow={acknowledgeShow} signatureObj={signatureObj} eSign={eSign} valueObject={valueObject} />
                    <div className="text-end">
                        <Button
                            type="button"
                            className="btn-default text-blacksix me-3 cancel-btn"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={ackShow}
                            loading={loading}
                            className="btn-primary text-white"
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </FormModal>


        </>
    );
};

export default ViewSignModal;
