import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button, Row, Col } from "react-bootstrap";
import "./style.scss";
import OnboardHeading from "../../../components/OnboardHeading";
import Steper from "../../../components/StepperProgress";
import HeaderForm from "../../../components/HeaderForm";
import PersonalInformation from "./PersonalInformation";
import ContactInfomation from './ContactInfomation'
import FederalTaxSetup from "./FederalTaxSetup";
import EmergencyContact from "./EmergencyContact";
import DirectDeposit from "./DirectDeposit";
import { postData, getData, getRevertUser } from "../../../api";
import { toast } from "react-toastify";
import OnboardingsTask from "./OnboardingsTask";
import EligibilityForm from "./EligibilityForm";
import DocumentUpload from "./DocumentUpload";
import Loader from "../../../components/Loader";
import FileNewHireReport from "./FileNewHireReport";
import revertIcon from "../../../assets/images/fa_refresh.svg";
import { useLocation } from "react-router-dom";
import {autocloseTiming} from '../../../api/regex';

const EmployeeOnboardingForms = () => {

    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState();
    const [stepNo, setStepNo] = useState();
    const [title, setTitle] = useState();
    const [employeeObject, setEmployeeObject] = useState({})
    const [personalTabActive, setPersonalTabActive] = useState(null);
    const [emergencyTabActive, setEmergencyTabActive] = useState(null);
    const [contactTabActive, setContactTabActive] = useState(null);
    const [fedralTabActive, setFedralTabActive] = useState(null);
    const [onboardTaskTabActive, setOnboardTaskTabActive] = useState(null);
    const [depositTabActive, setDepositTabActive] = useState(null);
    const [fileTabActive, setFileTabActive] = useState(null);
    const [eligibilityTabActive, setEligibilityTabActive] = useState(null);
    const [documentTabActive, setDocumentTabActive] = useState(null);
    const [stepArrays, setStepArrays] = useState(['personal_info', 'contact_info', 'emergency_contact', 'federal_tax', 'onboarding_tasks', 'direct_deposit', 'file_hire_new_report', 'eligibility_acknowledge', 'doc_upload']);
    const [newTabArray, setNewTabArray] = useState([]);
    const [stateList, setStateList] = useState();
    const [relationshipList, setRelationshipList] = useState([]);
    const [federalList, setFederalList] = useState();
    const [eligibilityList, setEligibilityList] = useState();
    const [countryList, setCountryList] = useState();
    const [tabsList, setTabsList] = useState([]);
    const [customPdfData, setCustomPdfData] = useState('');

    const companyName = localStorage.getItem("company_name");

    useEffect(() => {
        getClientStep();
        getStaticList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getTabTitle(step);
        activeTabChange(step)
    }, [step]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tabActive()
    }, [newTabArray]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const fragment = window.location.hash;
        if (fragment === '#onboarding_tasks') {
            setStep("onboarding_tasks");
            setCustomPdfData(state)
        }
    }, [step]); // eslint-disable-line react-hooks/exhaustive-deps


    const activeTabChange = async (step) => {
        let stepIndex = null;
        for (let index = 0; index < stepArrays.length; index++) {
            const element = stepArrays[index];
            if (step === element) {
                stepIndex = index + 1
            }
        }
        let newElement = stepArrays.splice(0, stepIndex);
        setNewTabArray([...newTabArray, ...newElement]);
    }

    const tabActive = () => {
        if (newTabArray.includes('personal_info')) {
            setPersonalTabActive('active')
        }
        if (newTabArray.includes('contact_info')) {
            setContactTabActive('active')
        }
        if (newTabArray.includes('emergency_contact')) {
            setEmergencyTabActive('active')
        }
        if (newTabArray.includes('federal_tax')) {
            setFedralTabActive('active')
        }
        if (newTabArray.includes('onboarding_tasks')) {
            setOnboardTaskTabActive('active')
        }
        if (newTabArray.includes('direct_deposit')) {
            setDepositTabActive('active')
        }
        if (newTabArray.includes('file_hire_new_report')) {
            setFileTabActive('active')
        }
        if (newTabArray.includes('eligibility_acknowledge')) {
            setEligibilityTabActive('active')
        }
        if (newTabArray.includes('doc_upload')) {
            setDocumentTabActive('active')
        }
    }
    const getClientStep = async () => {
        setLoading(true);
        // setStep("onboarding_tasks");
        const res = await getData("employee_onboarding_steps", {});
        if (res.status === true) {
            setStep(res.data.status);
            setLoading(false);
            setEmployeeObject(res.data)
            setTabsList(res.data.tabs)
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };
    const getStaticList = async () => {
        setLoading(true);
        const res = await postData("get_constant", {});
        if (res.status === true) {
            setStateList(res.data.stateList)
            setRelationshipList(res.data.emergencyContactList)
            setFederalList(res.data.federalList);
            setEligibilityList(res.data.eligibilityList);
            setCountryList(res.data.countryList);
            setLoading(false);
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    }

    const getTabTitle = (step) => {
        if (step === "personal_info") {
            setTitle("Personal information")
            setStepNo(1)
        } else if (step === "contact_info") {
            setTitle("Contact information")
            setStepNo(2)
        } else if (step === "emergency_contact") {
            setTitle("Emergency contact")
            setStepNo(3)
        } else if (step === "federal_tax") {
            setTitle("Federal tax")
            setStepNo(4)
        } else if (step === "onboarding_tasks") {
            setTitle("Onboarding tasks")
            setStepNo(5)
        } else if (step === "direct_deposit") {
            setTitle("Direct deposit")
            setStepNo(6)
        } else if (step === "file_hire_new_report") {
            setTitle("File new hire report")
            setStepNo(7)
        } else if (step === "eligibility_acknowledge") {
            setTitle("Eligibility and I9 forms")
            setStepNo(7)
        } else if (step === "doc_upload") {
            setTitle("Document upload")
            setStepNo(8)
        }
    };
    const nextStep = (whichStep) => {
        setStep(whichStep);
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }



    return (
        <>

            <>
                <HeaderForm />
                <div className="boarding-layout board-main-section">
                    <Row>
                        <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                            <OnboardHeading title="employee" subtitle={companyName}/>
                        </Col>
                        {
                            getRevertUser() ?
                                <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                    <div className="revert">
                                        <Button
                                            variant="danger"
                                            className="revert_btn"
                                            onClick={handleRevertUser}
                                        >
                                            <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                            Revert User
                                        </Button>
                                    </div>
                                </Col>
                                : ''
                        }
                    </Row>
                    {!loading && (
                        <div className="create-board">
                            <div className="board-container">
                                <div className="emp-onboard-tabs onboard-tabs">
                                    <div className="Mobi-view justify-content-start">
                                        <Steper className="Form-step" max={tabsList?.length} ProgressValue={stepNo} />
                                        <h4 className="ms-2 mb-0 align-self-center fw-700">{title}</h4>
                                    </div>
                                    <Tabs
                                        activeKey={step}
                                        onSelect={(key) => setStep(key)}
                                        className="mb-3 Desk-view"
                                    >
                                        {tabsList?.includes("personal_info") && (
                                            <Tab eventKey="personal_info" title="Personal information" disabled={!newTabArray.includes('personal_info')} tabClassName={personalTabActive} >
                                                <PersonalInformation nextStep={nextStep} onboardObject={employeeObject} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("contact_info") && (
                                            <Tab eventKey="contact_info" title="Contact information" disabled={!newTabArray.includes('contact_info')} tabClassName={contactTabActive}>
                                                <ContactInfomation nextStep={nextStep} onboardObject={employeeObject} stateList={stateList} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("emergency_contact") && (
                                            <Tab eventKey="emergency_contact" title="Emergency contact" disabled={!newTabArray.includes('emergency_contact')} tabClassName={emergencyTabActive}>
                                                <EmergencyContact nextStep={nextStep} onboardObject={employeeObject} relationshipList={relationshipList} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("federal_tax") && (
                                            <Tab eventKey="federal_tax" title="Federal tax" disabled={!newTabArray.includes('federal_tax')} tabClassName={fedralTabActive}>
                                                <FederalTaxSetup nextStep={nextStep} onboardObject={employeeObject} federalList={federalList} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("onboarding_tasks") && (
                                            <Tab eventKey="onboarding_tasks" title="Onboarding tasks" disabled={!newTabArray.includes('onboarding_tasks')} tabClassName={onboardTaskTabActive}>
                                                <OnboardingsTask nextStep={nextStep} onboardObject={employeeObject} customPdfData={customPdfData} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("direct_deposit") && (
                                            <Tab eventKey="direct_deposit" title="Direct deposit" disabled={!newTabArray.includes('direct_deposit')} tabClassName={depositTabActive}>
                                                <DirectDeposit nextStep={nextStep} onboardObject={employeeObject} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("file_hire_new_report") && (
                                            <Tab eventKey="file_hire_new_report" title="File new hire report" disabled={!newTabArray.includes('file_hire_new_report')} tabClassName={fileTabActive}>
                                                <FileNewHireReport nextStep={nextStep} onboardObject={employeeObject} stateList={stateList} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("eligibility_acknowledge") && (
                                            <Tab eventKey="eligibility_acknowledge" title="Eligibility and I9 forms" disabled={!newTabArray.includes('eligibility_acknowledge')} tabClassName={eligibilityTabActive}
                                            >
                                                <EligibilityForm nextStep={nextStep} onboardObject={employeeObject} eligibilityList={eligibilityList} countryList={countryList} />
                                            </Tab>
                                        )}
                                        {tabsList?.includes("doc_upload") && (
                                            <Tab eventKey="doc_upload" title="Document upload" disabled={!newTabArray.includes('doc_upload')} tabClassName={documentTabActive}>
                                                <DocumentUpload nextStep={nextStep} onboardObject={employeeObject} />
                                            </Tab>
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    )}
                    {loading && <Loader />}
                </div>
            </>

        </>

    );
};

export default EmployeeOnboardingForms;
