import React from 'react'

const PdfInitials = ({ page,inputId, value, onChange }) => {
    return (
        <div>
            <div className="pdf-initial">
            {value}
            </div>
        </div>
    )
}

export default PdfInitials;