import React, { useState, useEffect } from "react";
import ViewSignModal from "./ViewSignModal";
const PdfSignature = ({ page, inputId, value, onChange, e_sign }) => {
    const [acknowledged, setAcknowledged] = useState(false);
    const [viewSignShowModal, setViewSignShowModal] = useState(false);
    const [eSignValue, setESignValue] = useState('');

    useEffect(() => {
        if (e_sign !== '' && e_sign !== undefined && e_sign !== null) {
            setESignValue(e_sign);
        }
    }, [e_sign])

    useEffect(() => {
        if (value === "Signature" || value === '[Signature]') {
            setAcknowledged(false);
        } else {
            setAcknowledged(value);
        }
    }, [value])

    const ViewSignClick = () => {
        setViewSignShowModal(true);
    };

    const viewSignModalClose = () => {
        setViewSignShowModal(false);
    };

    const acknowledgedShow = (data) => {
        setAcknowledged(data);
        onChange(page, inputId, data);
    }

    return (
        <div>
            <div className="pdf-signature" onClick={() => ViewSignClick()}>
                {!acknowledged && value}
                {acknowledged && <img src={acknowledged} />}
            </div>
            {viewSignShowModal && <ViewSignModal show={viewSignShowModal} handleClose={viewSignModalClose} acknowledgedShow={acknowledgedShow} eSign={eSignValue} value={acknowledged} />}

        </div>
    )
}

export default PdfSignature;