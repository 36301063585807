import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Select from "react-select";
import InputGroup from 'react-bootstrap/InputGroup';
import { mustNumberswithPhone, onlyCharacterWithSpaceAndComma, mustNumbers, emptySpace, autocloseTiming } from "../../../api/regex";
import { postData ,phoneNumberFormat } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import SuccessSubmitModal from './SuccessSubmitModal'

const ContactInfomation = ({ nextStep, onboardObject, stateList }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        address: "",
        city: "",
        state: "",
        country: "USA",
        zip: "",
        phone_number: "",
        acknowledge: false,
    });
    const [errors, setErrors] = useState({});
    const [stateValue, setStateValue] = useState();
    const [autoUrl, setAutoUrl] = useState();
    const [successSubmitShowModal, setSuccessSubmitShowModal] = useState(false);

    useEffect(() => {
        if (onboardObject?.contact_info) {
            setEmployeeObject(onboardObject?.contact_info);
            let data = {
                label: onboardObject?.contact_info?.state,
                value: onboardObject?.contact_info?.state,
            }
            setStateValue(data);
            setEmployeeObject((prev) => ({
                ...prev,
                country: "USA"
            }));
        }
    }, [onboardObject]);


    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setEmployeeObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };


    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item.name,
                    label: item.name,
                    name: item.name,
                });
            });
        }
        return finalArr;
    };

    const handleStateOnChange = (data) => {
        setStateValue(data)
        setEmployeeObject((prev) => ({
            ...prev,
            state: data.value
        }));
    }


    const handlePhoneFormatChange = (e) => {
        const name = e.target.name;
        e.target.value = phoneNumberFormat(e.target.value)
        setEmployeeObject((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
    }

    const handlePhoneNumberKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        const keyValue = String.fromCharCode(keyCode);
        const isValidKey = /^\d$/.test(keyValue);
        const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
        const isInputValidLength = inputValue.length < 10;

        if (!isValidKey || !isInputValidLength) {
            event.preventDefault();
        }
    };


    const handleCheckAcknowledgeChange = (e) => {
        if (e.target.checked === true) {
            setEmployeeObject((prev) => ({
                ...prev,
                acknowledge: true,
            }));
        } else {
            setEmployeeObject((prev) => ({
                ...prev,
                acknowledge: false,
            }));
        }
    };

    //success modal 
    const successSubmitShowModalClose = () => {
        setSuccessSubmitShowModal(false);
    };

    const findFormErrors = () => {
        const { address, city, state, zip, phone_number, acknowledge } = employeeObject;
        const newErrors = {};
        if (!address || address === "") newErrors.address = "Please enter address.";
        else if (address.length < 5) {
            newErrors.address = "The address name should be  5 to 250 characters long.";
        } else if (address.length > 250) {
            newErrors.address = "The address name should be  5 to 250 characters long.";
        }
        if (!city || city === "") newErrors.city = "Please enter your city.";
        else if (!city?.match(onlyCharacterWithSpaceAndComma)) {
            newErrors.city = "The city name should be alphabetic.";
        }
        else if (city.length < 4) {
            newErrors.city = "The city name should be  4 to 20 characters long.";
        } else if (city.length > 20) {
            newErrors.city = "The city name should be  4 to 20 characters long.";
        }
        else if (!city?.match(emptySpace)) {
            newErrors.city = "The city name should be alphabetic.";
        }
        if (!state || state === "") newErrors.state = "Please enter your state.";
        if (!zip || zip === "") newErrors.zip = "Please enter your zip.";
        else if (!zip.toString()?.match(mustNumbers)) {
            newErrors.zip = "The zip should be numeric.";
        }
        else if (zip.length !== 5) {
            newErrors.zip = "The zip should be 5 digits.";
        }
        if (!phone_number || phone_number === "") newErrors.phone_number = "Please enter your phone number.";
        else if (phone_number !== "") {
            const Phone_numbers_Only = phone_number?.replace(/\D/g, '');
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.phone_number = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.phone_number = "Please enter a 10 digit phone number";
            }
        }
        if (onboardObject?.skip_gusto_payroll === 1) {
            if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
        }
        return newErrors;
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            contactOnboard();
        }
    };

    const contactOnboard = async () => {
        setLoading(true);
        let data = {
            stage: "contact_info",
            ...employeeObject
        }
        const res = await postData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            if (onboardObject?.skip_gusto_payroll === 1) {
                localStorage.setItem("guhroo_user", res.user_type);
                localStorage.setItem("guhroo_user_details", JSON.stringify(res));
                localStorage.setItem("guhroo_onbord_status", res.onboard_status);
                localStorage.setItem("user_completion_status ", res.user_completion_status);
                setAutoUrl(res.auto_url)
                setSuccessSubmitShowModal(true);
                setErrors({})
            } else {
                nextStep(res.next_steps)
            }

        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <>
                    <div className="board-right board-tab-sections">
                        <h5 className="mb-3">Contact Information</h5>

                        <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                            <Row>
                                <Col md={8}>
                                    <Form.Group className="mb-3" controlId="address">
                                        <Form.Label>Address <span className="asterisk">*</span> </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter address"
                                            value={employeeObject.address}
                                            onChange={handleInput}
                                            isInvalid={!!errors.address}
                                            name="address"
                                            maxLength="250"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="city">
                                        <Form.Label>City <span className="asterisk">*</span> </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter city"
                                            value={employeeObject.city}
                                            onChange={handleInput}
                                            isInvalid={!!errors.city}
                                            name="city"
                                            maxLength="20"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.city}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Label>State <span className="asterisk">*</span> </Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            options={formatSelectOptions(stateList)}
                                            onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                                            value={stateValue}
                                            placeholder="Select state"
                                            className="custom-select"
                                        />
                                        {
                                            validated && (employeeObject?.state === '' || employeeObject?.state === null) &&
                                            <div className="err-feedback">Please enter state.</div>
                                        }

                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Label>Country <span className="asterisk">*</span> </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter country"
                                            defaultValue="USA"
                                            value={employeeObject?.country}
                                            isInvalid={!!errors.country}
                                            name="country"
                                            required
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="zip">
                                        <Form.Label>Zip Code <span className="asterisk">*</span> </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter zip code"
                                            value={employeeObject.zip}
                                            onChange={handleInput}
                                            isInvalid={!!errors.zip}
                                            name="zip"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.zip}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="phone_number">
                                        <Form.Label>Phone number <span className="asterisk">*</span> </Form.Label>
                                        <InputGroup className="phone-group">
                                            <InputGroup.Text>
                                                +1
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter phone number"
                                                value={employeeObject.phone_number}
                                                onChange={handleInput}
                                                isInvalid={!!errors.phone_number}
                                                name="phone_number"
                                                // maxLength="10"
                                                onBlur={handlePhoneFormatChange}
                                                onKeyPress={handlePhoneNumberKeyPress}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone_number}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                onboardObject?.skip_gusto_payroll === 1 &&
                                <Row className="check-custom mt-5">
                                    <Col md={12}>
                                        <Form.Group className="mb-3 bank-check contact-check ack-step-last" controlId="acknowledge">
                                            <Form.Check
                                                label={
                                                    <span>
                                                        I agree to the
                                                        <a className="ms-1" href="https://flows.gusto.com/terms" target="_blank" rel="noopener noreferrer">
                                                            Terms of Service
                                                        </a>
                                                    </span>
                                                }
                                                name="acknowledge"
                                                type="checkbox"
                                                checked={employeeObject.acknowledge}
                                                value="1"
                                                onChange={(e) => handleCheckAcknowledgeChange(e)}
                                                id="acknowledge"
                                                required
                                            />
                                            {
                                                validated && errors?.acknowledge &&
                                                <div className="err-feedback">{errors?.acknowledge}</div>
                                            }

                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            <div className="text-end mt-4">
                                <Button
                                    type="button"
                                    className="btn-outline-primary me-2"
                                    onClick={() => nextStep("personal_info")}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={loading}
                                    loading={loading}
                                    className="btn-primary text-white"
                                >
                                    {
                                        onboardObject?.skip_gusto_payroll === 1 ? "Submit" : "Save & Continue"
                                    }

                                </Button>
                            </div>

                        </Form>

                    </div>
                    {successSubmitShowModal && <SuccessSubmitModal show={successSubmitShowModal} onClose={successSubmitShowModalClose} autoUrl={autoUrl} user={onboardObject} />}
                </>
            )}
            {loading && <Loader />}
        </>
    )
}

export default ContactInfomation