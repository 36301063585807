import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { FiHelpCircle } from 'react-icons/fi';
import { postData ,convertUTCToLocalDate} from "../../api";
import { toast } from "react-toastify";
import moment from 'moment'
import Loader from "../../components/Loader";
import "./style.scss";
import {autocloseTiming} from '../../api/regex';



const PayrollScheme = ({ companySettingsObject, payFrequencyList, firstPayList, secondPayList }) => {

    const focusDate = useRef(null);
    const endFocusDate = useRef(null);
    const deadlineFocusDate = useRef(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        type: "",
        first_pay_day: "",
        second_pay_day: "",
        firstphase: "",
        secondphase: "",
        deadlineDate: new Date()
    });
    const [errors, setErrors] = useState({});
    const [dateInputs, setDateInputs] = useState({
        firstphase: null,
        secondphase: null,
    });
    // const [inputArrayList, setInputArrayList] = useState([clientObject]);
    const [disable, setDisable] = useState(true);
    // const timeZoneOffset = new Date().getTimezoneOffset();

    useEffect(() => {
        if (companySettingsObject?.payroll) {
            setClientObject(companySettingsObject?.payroll[0])
            setDateInputs({
                // firstphase: new Date(companySettingsObject?.payroll[0].firstphase),
                // secondphase: new Date(companySettingsObject?.payroll[0].secondphase),

                // firstphase: moment.utc(companySettingsObject?.payroll[0]?.firstphase).add(timeZoneOffset, 'minutes').toDate(),
                // secondphase: moment.utc(companySettingsObject?.payroll[0]?.secondphase).add(timeZoneOffset, 'minutes').toDate()
            
                firstphase: convertUTCToLocalDate(companySettingsObject?.payroll[0]?.firstphase),
                secondphase: convertUTCToLocalDate(companySettingsObject?.payroll[0]?.secondphase),
            });
        }
    }, [companySettingsObject]);

    useEffect(() => {
        if (dateInputs.firstphase !== null) {
            let deadlineDate = new Date(dateInputs?.firstphase?.getTime());
            deadlineDate.setDate(dateInputs.firstphase?.getDate() - 2);
            setClientObject((prev) => ({
                ...prev,
                deadlineDate: deadlineDate
            }));
        }

    }, [dateInputs]);


    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setClientObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };


    const renderTooltip =
        <div>
            The last date of the first pay period to help calculate future pay periods. This can be the same date as the first pay date.
        </div>


    const handleEndFocusDate = () => {
        const datepickerElement = endFocusDate.current;
        datepickerElement.setFocus(true);
    };

    const handleFocusDate = () => {
        const datepickerElement = focusDate.current;
        datepickerElement.setFocus(true);
    };

    const handleDeadlineFocusDate = () => {
        const datepickerElement = deadlineFocusDate.current;
        datepickerElement.setFocus(true);
    };

    const findFormErrors = () => {
        const { type, first_pay_day, second_pay_day, firstphase, secondphase } = clientObject;
        let newErrors = {};
        if (!type || type === "") newErrors.type = "Please enter pay frequency.";
        if (!first_pay_day || first_pay_day === "") newErrors.first_pay_day = "Please select  month.";
        if (!second_pay_day || second_pay_day === "") newErrors.second_pay_day = "Please select month.";
        if (!firstphase || firstphase === "" || firstphase === null) newErrors.firstphase = "Please enter your first pay date";
        if (!secondphase || secondphase === "" || secondphase === null) newErrors.secondphase = "Please enter your first pay period end date";
        else if (clientObject.type !== "semimonthly custom") {
            newErrors = {}
        }
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (!disable) {
                payrollScheme();
            }

        }
    };

    const payrollScheme = async () => {
        setLoading(true);
        const AddObject = structuredClone(clientObject);
        AddObject.firstphase = moment(clientObject.firstphase).format("YYYY-MM-DD");
        let data = {
            stage: "payroll",
            ...AddObject
        }
        setLoading(true);
        const res = await postData("client_setting", {}, data);
        if (res.status === true) {
            setLoading(false);
            setDisable(true)
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const minTwoDigit = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    const formatDates = (date) => {
        const day = minTwoDigit(date.getDate());
        const month = minTwoDigit(date.getMonth() + 1);
        const year = date.getFullYear();
        let val = `${year}-${month}-${day}`;
        return val;
    };

    const selectUploadDt = (dateSel, name) => {
        const date = new Date(`${dateSel}`);
        const chkDt = date.getDate();

        if (chkDt > 0) {
            let val = formatDates(date);
            setClientObject((prev) => ({
                ...prev,
                [name]: val
            }));
            setDateInputs((prev) => ({
                ...prev,
                [name]: dateSel,
            }));
        }

    };


    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <div className="payroll-sub-title">
                        Why do we need to ask for this? We need to know when to pay your employees. Some states have <a href="https://www.dol.gov/agencies/whd/state/payday" target="_blank" rel="noopener noreferrer">laws around when you must pay your employees. </a> Please choose pay schedules that are legal for your employees.
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form my-3 payroll-company">
                        <div className="row mul-box">
                            <Col md={6} className="mb-2">
                                <h4 className="add-title">Payroll </h4>
                            </Col>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="type">
                                        <Form.Label>Pay frequency <span className="asterisk">*</span> </Form.Label>
                                        <Form.Select
                                            value={clientObject?.type}
                                            onChange={(e) => handleInput(e)}
                                            disabled={true}
                                            name="type"
                                            required
                                            isInvalid={!!errors.type}
                                            size="lg"
                                        >
                                            <option value="">Select pay frequency</option>
                                            {payFrequencyList &&
                                                payFrequencyList.map((item, index) => (
                                                    <option key={item.id} value={item.value}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className={`${clientObject.type === "semimonthly custom" ? "d-block" : 'd-none'}`}>
                                    <Form.Group className="mb-3" controlId="first_pay_day">
                                        <Form.Label>First pay day of month <span className="asterisk">*</span> </Form.Label>
                                        <Form.Select
                                            value={clientObject?.first_pay_day}
                                            onChange={(e) => handleInput(e)}
                                            disabled
                                            name="first_pay_day"
                                            required
                                            size="lg"
                                        >
                                            <option value="">Select month</option>
                                            {firstPayList &&
                                                firstPayList.map((item, index) => (
                                                    <option key={item.id} value={item.value}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_pay_day}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                                <Col md={4} className={`${clientObject.type === "semimonthly custom" ? "d-block" : 'd-none'}`}>
                                    <Form.Group className="mb-3" controlId="second_pay_day">
                                        <Form.Label>Second pay day of month <span className="asterisk">*</span> </Form.Label>
                                        <Form.Select
                                            value={clientObject?.second_pay_day}
                                            onChange={(e) => handleInput(e)}
                                            disabled
                                            name="second_pay_day"
                                            required
                                            size="lg"
                                        >
                                            <option value="">Select month</option>
                                            {secondPayList &&
                                                secondPayList.map((item, index) => (
                                                    <option key={item.id} value={item.value}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.second_pay_day}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="card_container row">
                                <div className="cards">
                                    <Form.Group className="position-relative mb-3" controlId="firstphase">
                                        <Form.Label>First pay date <span className="asterisk">*</span> </Form.Label>
                                        <DatePicker
                                            ref={focusDate}
                                            className="datepicker-payscheme"
                                            selected={dateInputs?.firstphase ? dateInputs?.firstphase : null}
                                            onChange={(date) =>
                                                selectUploadDt(date, "firstphase")
                                            }
                                            name="firstphase"
                                            dateFormat="MM-dd-yyyy"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            calendarIcon={true}
                                            closeCalendar={true}
                                            clearIcon={true}
                                            disabled
                                        />
                                        <span className="calendar-icon" onClick={handleFocusDate}></span>
                                        {
                                            validated && clientObject?.firstphase === '' &&
                                            <div className="err-feedback">{errors?.firstphase}</div>
                                        }
                                    </Form.Group>
                                </div>
                                <div className="cards">
                                    <Form.Group className="position-relative mb-3" controlId="deadlineDate">
                                        <Form.Label>Deadline to run payroll </Form.Label>
                                        <DatePicker
                                            ref={deadlineFocusDate}
                                            className="datepicker-payscheme"
                                            selected={clientObject.deadlineDate}
                                            name="deadlineDate"
                                            dateFormat="MM-dd-yyyy"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            calendarIcon={true}
                                            closeCalendar={true}
                                            clearIcon={true}
                                            disabled
                                        />
                                        <span className="calendar-icon" onClick={handleDeadlineFocusDate}></span>
                                    </Form.Group>
                                </div>
                                <div className="cards">
                                    <Form.Group className="position-relative mb-3" controlId="secondphase">
                                        <Form.Label className="me-2">First pay period end date <span className="asterisk">*</span> </Form.Label>
                                        <Tooltip placement="left" overlay={renderTooltip} overlayClassName="pay-tooltip">
                                            <FiHelpCircle className="help-circle" />
                                        </Tooltip>
                                        <DatePicker
                                            ref={endFocusDate}
                                            className="datepicker-payscheme"
                                            selected={dateInputs?.secondphase ? dateInputs?.secondphase : null}
                                            onChange={(date) =>
                                                selectUploadDt(date, "secondphase")
                                            }
                                            name="secondphase"
                                            dateFormat="MM-dd-yyyy"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            calendarIcon={true}
                                            closeCalendar={true}
                                            clearIcon={true}
                                            disabled
                                        />
                                        <span className="calendar-icon" onClick={handleEndFocusDate}></span>
                                        {
                                            validated && clientObject?.secondphase === '' &&
                                            <div className="err-feedback">{errors?.secondphase}</div>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            )
            }
            {loading && <Loader />}

        </>
    )
}

export default PayrollScheme