
import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { toast } from "react-toastify";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PdfInput from "../../components/CustomPdf/Inputs";
import PdfCheckBox from '../../components/CustomPdf/Inputs/checkbox'
import PdfInitials from "../../components/CustomPdf/Inputs/Initials";
import PdfSignature from "../../components/CustomPdf/Inputs/Signature";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { checkMarkBase64 } from './Inputs/checkMarkBase64'
import ViewPdf from "../../components/PdfViewer";
import { postUploadData } from "../../api";
import { autocloseTiming } from '../../api/regex'
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";
import './style.scss';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ file, pdfData, pdfFullData, pageType }) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(1);
  const canvasData = JSON.parse(pdfData?.canvas_settings);
  const settingsData = useRef(JSON.parse(pdfData?.settings));
  const [active, setActive] = useState("2");
  const [disableZoomOut, setDisableZoomOut] = useState(false);
  const [disableZoomIn, setDisableZoomIn] = useState(false);
  const [selectPage, setSelectPage] = useState(1);
  const [enableErrorMsg, setEnableErrorMsg] = useState(false);
  const minimumZoomOutLevel = 0.3;
  const minimumZoomInLevel = 1.5;
  // const minimumZoomInWidth = canvasData?.width + 300;
  const taskMinimumZoomOutWidth = 200;
  const employeeMinimumZoomOutWidth = 500;
  const minimumZoomInWidth = 1000;
  const [minimumPageWidth, setMinimumPageWidth] = useState(canvasData?.width + 40);
  const [minimumPageHeight, setMinimumPageHeight] = useState(20);

  function onPdfLoadSuccess(numPages) {
    setNumPages(numPages);
  }

  const handleChange = (page, inputId, newValue) => {
    settingsData.current[page][inputId].value = newValue;
  }

  useEffect(() => {
    // Get the react-pdf__Page element
    const reactPdfPage = document.querySelector('.react-pdf__Page');

    if (reactPdfPage) {
      // Apply styles dynamically
      reactPdfPage.style.color = 'red';
      reactPdfPage.style.width = `${canvasData?.width}px`; // Use template literals to concatenate values
      reactPdfPage.style.border = '1px solid red';
      reactPdfPage.style.overflow = 'auto';
      reactPdfPage.style.height = `${canvasData?.height}px`;;
    }

  }, [canvasData, minimumPageWidth, minimumPageHeight]);


  useEffect(() => {
    if (selectPage > numPages) {
      setCurrentPage(Number(1));
    } else if (selectPage > 0) {
      setCurrentPage(Number(selectPage));
    }
  }, [selectPage]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleInputChange(event) {
    const { value } = event.target;
    setSelectPage(value);
  }

  const handleZoomIn = () => {
    if (zoom < minimumZoomInLevel && minimumPageWidth < minimumZoomInWidth) {
      setZoom(prevZoom => prevZoom + 0.01);
      setDisableZoomOut(false);
      setDisableZoomIn(false);
      setMinimumPageWidth(minimumPageWidth + 20)
      setMinimumPageHeight(minimumPageHeight + 20)
    } else {
      setDisableZoomIn(true);
    }

  }

  const handleZoomOut = () => {
    let zoomOutLevel;
    if (pageType === 'employee-onboard') {
      zoomOutLevel = employeeMinimumZoomOutWidth;
    } else {
      zoomOutLevel = taskMinimumZoomOutWidth
    }

    if (zoom >= minimumZoomOutLevel && minimumPageWidth > zoomOutLevel) {
      setZoom(prevZoom => prevZoom - 0.01);
      setDisableZoomOut(false)
      setDisableZoomIn(false);
      setMinimumPageWidth(minimumPageWidth - 20)
      setMinimumPageHeight(minimumPageHeight - 20)
    } else {
      setDisableZoomIn(false);
      setDisableZoomOut(true);
    }
  }

  const handleZoomReset = () => {
    setZoom(1);
    setMinimumPageWidth(canvasData?.width + 40)
    setMinimumPageHeight(canvasData?.height + 20)
    setDisableZoomOut(false);
    setDisableZoomIn(false);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setSelectPage(currentPage - 1);
    }
  };


  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
      setSelectPage(currentPage + 1);
    }
  };

  const handleCancelBtnClick = () => {
    if (pageType === 'employee-onboard') {
      navigate('/employee-onboard-sections#onboarding_tasks', { state: pdfFullData });
    } else if (pageType === 'profile_task') {
      const state = { state: "tasks_from_pdf", user_id: pdfFullData.user_id };
      navigate('/profile/' + pdfFullData.user_id, { state: state });
    }
  }

  const handleSaveBtnClick = () => {
    const errorMessages = [];
    for (const key in settingsData.current) {
      const item = settingsData.current[key];
      for (const subKey in item) {
        const subItem = item[subKey];
        if (subItem.needed && (subItem.value === '' || subItem.value === '[Signature]')) {
          subItem.showError = true;
          errorMessages.push(`Validation error for item ${key}-${subKey}: Value is required.`);
        }
        else {
          subItem.showError = false;
        }
        setEnableErrorMsg(true);
      }
    }

    if (errorMessages.length > 0) {
      //call toast message
      toast.error('Please ensure all mandatory fields are filled out.', { theme: "colored", autoClose: autocloseTiming });
    } else {
      // api call
      savePdf();
    }
  }

  const DownloadPdf = async () => {
    const existingPdfBytes = await fetch(file).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    for (const pageKey in settingsData.current) {
      const pageData = settingsData.current[pageKey];
      const page = pdfDoc.getPage(pageKey - 1);
      for (const fieldKey in pageData) {
        const fieldData = pageData[fieldKey];
        const type = fieldData.type;
        const scale = fieldData.scale || 1;
        const top = fieldData.top;
        const left = fieldData.left;
        const value = fieldData.value;
        const place_holder = fieldData.place_holder;
        const fontSize = fieldData.font_size || 14;
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const zIndex = 99

        const positionX = left * scale;
        const positionY = page.getHeight() - top * scale;

        if (type === 'text') {
          page.drawText(value, { x: positionX, y: positionY, size: fontSize, font, zIndex: zIndex });
        }
        else if (type === 'signature') {
          if (value !== '[Signature]') {
            const pdfImage = await pdfDoc.embedPng(value);
            page.drawImage(pdfImage, {
              x: positionX,
              y: positionY,
              width: 40,
              height: 40,
              zIndex: zIndex
            });
          }
          // If no value is provided or the value is not a PNG file, the signature field will be left blank.
        }
        else if (type === 'initials') {
          page.drawText(value, { x: positionX, y: positionY, size: fontSize, font, zIndex: zIndex });
        }
        else if (type === 'checkbox') {
          const checkboxSize = 12; // Size of the checkbox

          // Draw the checkbox border
          page.drawRectangle({
            x: positionX,
            y: positionY,
            width: checkboxSize,
            height: checkboxSize,
            borderColor: rgb(0, 0, 0),
            color: rgb(1, 1, 1),
            borderWidth: 1,
            zIndex: zIndex
          });

          if (value) {
            // Embed the checkmark image (base64 encoded PNG)
            const pdfImage = await pdfDoc.embedPng(checkMarkBase64);

            // Draw the checkmark image inside the checkbox
            page.drawImage(pdfImage, {
              x: positionX, // Adjust the position if needed
              y: positionY, // Adjust the position if needed
              width: checkboxSize, // Make sure it fits inside the checkbox
              height: checkboxSize, // Make sure it fits inside the checkbox
              zIndex: zIndex
            });
          }

          // Draw the text next to the checkbox
          page.drawText(place_holder, {
            x: positionX + checkboxSize + 10, // Adjust the text position
            y: positionY,
            font,
            size: 12,
            color: rgb(0, 0, 0), // Text color is black
            zIndex: zIndex
          });
        }
      }
    }
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const modifiedPdfUrl = URL.createObjectURL(modifiedPdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
    downloadLink.download = 'modified_pdf.pdf';

    // Trigger the download by programmatically clicking the link
    downloadLink.click();

  }


  const savePdf = async () => {
    const existingPdfBytes = await fetch(file).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    for (const pageKey in settingsData.current) {
      const pageData = settingsData.current[pageKey];
      const page = pdfDoc.getPage(pageKey - 1);
      for (const fieldKey in pageData) {
        const fieldData = pageData[fieldKey];
        const type = fieldData.type;
        const scale = fieldData.scale || 1;
        const top = fieldData.top;
        const left = fieldData.left;
        const value = fieldData.value;
        const place_holder = fieldData.place_holder;
        const fontSize = fieldData.font_size || 14;
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const zIndex = 99

        const positionX = left * scale;
        const positionY = page.getHeight() - top * scale;

        if (type === 'text') {
          page.drawText(value, { x: positionX, y: positionY, size: fontSize, font, zIndex: zIndex });
        }
        else if (type === 'signature') {
          if (value !== '[Signature]') {
            const pdfImage = await pdfDoc.embedPng(value);
            page.drawImage(pdfImage, {
              x: positionX,
              y: positionY,
              width: 40,
              height: 40,
              zIndex: zIndex
            });
          }
          // If no value is provided or the value is not a PNG file, the signature field will be left blank.
        }
        else if (type === 'initials') {
          page.drawText(value, { x: positionX, y: positionY, size: fontSize, font, zIndex: zIndex });
        }
        else if (type === 'checkbox') {
          const checkboxSize = 12; // Size of the checkbox

          // Draw the checkbox border
          page.drawRectangle({
            x: positionX,
            y: positionY,
            width: checkboxSize,
            height: checkboxSize,
            borderColor: rgb(0, 0, 0),
            color: rgb(1, 1, 1),
            borderWidth: 1,
            zIndex: zIndex
          });

          if (value) {
            // Embed the checkmark image (base64 encoded PNG)
            const pdfImage = await pdfDoc.embedPng(checkMarkBase64);

            // Draw the checkmark image inside the checkbox
            page.drawImage(pdfImage, {
              x: positionX, // Adjust the position if needed
              y: positionY, // Adjust the position if needed
              width: checkboxSize, // Make sure it fits inside the checkbox
              height: checkboxSize, // Make sure it fits inside the checkbox
              zIndex: zIndex
            });
          }

          // Draw the text next to the checkbox
          page.drawText(place_holder, {
            x: positionX + checkboxSize + 10, // Adjust the text position
            y: positionY,
            font,
            size: 12,
            color: rgb(0, 0, 0), // Text color is black
            zIndex: zIndex
          });
        }
      }
    }
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const modifiedPdfUrl = URL.createObjectURL(modifiedPdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
    downloadLink.download = 'modified_pdf.pdf';

    submitPdfCall(downloadLink);
  }

  const submitPdfCall = async (downloadLink) => {
    setLoading(true);
    const response = await fetch(downloadLink.href);
    const pdfBlob = await response.blob();
    const formData = new FormData();
    if (pageType === 'profile_task') {
      formData.append("task_id", pdfFullData?.id);
    } else if (pageType === 'employee-onboard') {
      formData.append("task_id", pdfFullData?.task_id);
    }
    formData.append("pdf_file", pdfBlob);
    formData.append("page_type", pageType);
    formData.append("settings", JSON.stringify(settingsData?.current));
    formData.append("canvas_settings", pdfFullData?.custom_documents?.canvas_settings);
    formData.append("document_name", pdfFullData?.name);

    const res = await postUploadData("custom-docs-completed", {}, formData);
    if (res.status === true) {
      setLoading(false);
      if (pageType === 'employee-onboard') {
        navigate('/employee-onboard-sections#onboarding_tasks', { state: pdfFullData });
      } else if (pageType === 'profile_task') {
        const state = { state: "tasks_custom_pdf", user_id: pdfFullData.user_id };
        navigate('/profile/' + pdfFullData.user_id, { state: state });
      }
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
    } else {
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });
      setLoading(false);
    }
  }
  return (
    <>
      {!loading && (
        <>
          <div className="text-center pdf-toolbar mb-3">
            <div className="btn-group me-2">
              <Button className="btn btn-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
              <Button className="btn btn-primary" onClick={handleNextPage} disabled={currentPage === numPages}>Next</Button>
              <Button className="btn btn-primary" onClick={handleZoomIn} disabled={disableZoomIn}>Zoom In</Button>
              <Button className="btn btn-primary" onClick={handleZoomOut} disabled={disableZoomOut}>Zoom Out</Button>
              <Button className="btn btn-primary" onClick={handleZoomReset}>100%</Button>
            </div>
            <div className="btn-group">
              <span className="btn btn-light hidden-xs">Page: </span>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  value={selectPage}
                  onChange={handleInputChange}
                />
                <span className="btn btn-light hidden-xs">/ {numPages}</span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              key={1}
              className={active === "1" ? "pdf-btn-active" : "pdf-normal-btn"}
              id="1"
              onClick={DownloadPdf} >
              <img src={revertIcon} alt="revert" className="revert-img me-1" />
              Preview
            </Button>
            <Button
              key={2}
              className={active === "2" ? "pdf-btn-active" : "pdf-normal-btn"}
              id="2"
              onClick={handleSaveBtnClick} >
              <img src={revertIcon} alt="revert" className="revert-img me-1" />
              Submit
            </Button>
            <Button
              key={3}
              className={active === "3" ? "pdf-btn-active" : "pdf-normal-btn"}
              id="3"
              onClick={handleCancelBtnClick}
            >
              <img src={revertIcon} alt="revert" className="revert-img me-1" />
              Cancel
            </Button>

          </div>
          <div className="react-custom-pdf"
          // style={{
          //   overflowX: pageType === 'employee-onboard' ? 'hidden' : ''
          // }}
          >
            <div id="html-to-pdf" style={{ position: "relative", width: canvasData?.width, height: canvasData?.height }}>
              <div style={{
                transform: `scale(${zoom})`,
                // overflow: pageType === 'employee-onboard' ? 'auto' : ''
              }}>

                <ViewPdf
                  file={file}
                  key={`page_${currentPage}`}
                  pageNumber={currentPage}
                  pageCount={numPages}
                  // scale={zoom}
                  onPdfLoadSuccess={onPdfLoadSuccess}
                />
                {/* Render inputs based on current page */}
                {
                  Object.entries(settingsData.current).map(([pageNumber, pageData]) => {
                    if (pageNumber !== currentPage.toString()) return null;

                    return Object.entries(pageData).map(([inputId, pageInputData]) => {
                      const { type, top, left, width, height, value } = pageInputData;

                      if (type === 'text') {
                        return (
                          <div
                            key={`text_${inputId}`}
                            style={{
                              position: 'absolute',
                              top: `${top}px`,
                              left: `${left}px`,
                              width: `${width}px`,
                              height: `${height}px`,
                              zIndex: 2
                            }}
                          >
                            <PdfInput
                              page={pageNumber}
                              inputId={inputId}
                              value={value || ''}
                              onChange={(page, inputId, newValue) => handleChange(page, inputId, newValue)}
                            />
                            {pageInputData.showError && enableErrorMsg && <p className="mb-0 err-feedback">This field is required</p>}
                          </div>
                        );
                      } else if (type === 'checkbox') {
                        return (
                          <div
                            key={`checkbox_${inputId}`}
                            style={{
                              position: 'absolute',
                              top: `${top}px`,
                              left: `${left}px`,
                              width: `${width}px`,
                              height: `${height}px`,
                              zIndex: 2
                            }}
                          >
                            <PdfCheckBox
                              page={pageNumber}
                              inputId={inputId}
                              value={value || ''}
                              onChange={(page, inputId, newValue) => handleChange(page, inputId, newValue)}
                            />
                            {pageInputData.showError && enableErrorMsg && <p className="mb-0 err-feedback">This field is required</p>}
                          </div>
                        );
                      } else if (type === 'initials') {
                        return (
                          <div
                            key={`initials_${inputId}`}
                            style={{
                              position: 'absolute',
                              top: `${top}px`,
                              left: `${left}px`,
                              width: `${width}px`,
                              height: `${height}px`,
                              zIndex: 2
                            }}
                          >
                            <PdfInitials
                              page={pageNumber}
                              inputId={inputId}
                              value={value || ''}
                              onChange={(page, inputId, newValue) => handleChange(page, inputId, newValue)}
                            />
                          </div>
                        );
                      } else if (type === 'signature') {
                        return (
                          <div
                            key={`signature_${inputId}`}
                            style={{
                              position: 'absolute',
                              top: `${top}px`,
                              left: `${left}px`,
                              width: `${width}px`,
                              height: `${height}px`,
                              zIndex: 2
                            }}
                          >
                            <PdfSignature
                              page={pageNumber}
                              inputId={inputId}
                              // value={"Signature"}
                              value={value ? value : "Signature"}
                              onChange={(page, inputId, newValue) => handleChange(page, inputId, newValue)}
                              e_sign={value !== '[Signature]' ? value : pdfFullData?.e_sign}
                            />
                            {pageInputData.showError && enableErrorMsg && <p className="mb-0 err-feedback">This field is required</p>}
                          </div>
                        );
                      }

                      return null;
                    });
                  })
                }

              </div>
            </div>
          </div>
        </>
      )
      }
      {loading && <Loader />}
    </>
  );
}

export default PdfViewer;


