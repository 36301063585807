import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser } from "../../api";
import Loader from "../../components/Loader";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row, Container, Table } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import Badge from 'react-bootstrap/Badge';
import revertIcon from "../../assets/images/fa_refresh.svg";

const SignDocuments = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [signDocumentsList, setSignDocumentsList] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [missingRequirement, setMissingRequirement] = useState([]);
    const [missingTitle, setMissingTitle] = useState('');
    const [missingSubTitle, setMissingSubTitle] = useState('');
    const [syncMsg, setSyncMsg] = useState('');


    useEffect(() => {
        getSignDocumentsList();
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/sign-documents') {
            document.body.classList.add('feedback-remove');
        } else {
            document.body.classList.remove('feedback-remove');
        }
    }, [window.location.pathname === '/sign-documents']);


    const getSignDocumentsList = async () => {
        setLoading(true);
        let obj = {
            stage: "company-forms"
        };
        try {
            const res = await postData("get-all-forms", {}, obj);
            if (res?.status === true) {
                setSignDocumentsList(res?.data);
                setCompanyName(res?.company_name);
                setMissingRequirement(res?.missing_requirement);
                setMissingTitle(res?.missing_title);
                setMissingSubTitle(res?.missing_subtitle);
                setSyncMsg(res?.syncMsg)
                setLoading(false);
            }
        } catch (err) { }
    };


    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            {!loading && (
                <Container fluid>
                    <div className="paystub-view new_signDoc">
                        <Row>
                            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                                <OnboardHeading title="Sign documents" subtitle={companyName} />
                            </Col>
                            {
                                getRevertUser() ?
                                    <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                        <div className="revert">
                                            <Button
                                                variant="danger"
                                                className="revert_btn"
                                                onClick={handleRevertUser}
                                            >
                                                <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                                Revert User
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>

                        {
                            signDocumentsList?.length !== 0 ?
                                <div className="sign-doc-table sign-comapny-table">
                                    {
                                        missingRequirement?.length !== 0 &&
                                        <div className="inside-table-sign-error">
                                            <div className="sign-no-data">
                                                <h4>{missingTitle}</h4>
                                                <div>{missingSubTitle}</div>
                                                <div className="sign-error my-2">
                                                    <ul>
                                                        {
                                                            missingRequirement?.map((data, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        {
                                                                            data?.link_to === 'add_address' &&
                                                                            <li>
                                                                                <div className="sign-doc-name"
                                                                                    onClick={() => navigate('/company-settings', { state: "add_address" })}
                                                                                >
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'federal_tax' &&
                                                                            <li>
                                                                                <div className="sign-doc-name"
                                                                                    onClick={() => navigate('/company-settings', { state: "federal_tax" })}
                                                                                >
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'employer-state-tax' &&
                                                                            <li>
                                                                                <div className="sign-doc-name"
                                                                                    onClick={() => navigate('/employer-state-tax')}
                                                                                >
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'sign-documents' &&
                                                                            <li>
                                                                                <div className="sign-doc-name same-sign-link" >
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'bank_info' &&
                                                                            <li>
                                                                                <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "bank_info" })}>
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'signatory' &&
                                                                            <li>
                                                                                <div className="sign-doc-name" onClick={() => navigate('/signatory')}>
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'industry' &&
                                                                            <li>
                                                                                <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "industry" })}>
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.link_to === 'payroll' &&
                                                                            <li>
                                                                                <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "payroll" })}>
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            data?.id === 'add_employees' &&
                                                                            <li>
                                                                                <div className="sign-doc-name" onClick={() => window.open(data?.link_to, "_self")}>
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }

                                                                        {
                                                                            data?.link_to === '#' &&
                                                                            <li>
                                                                                <div className="sign-doc-name same-sign-link" >
                                                                                    {data?.title}
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    </React.Fragment>
                                                                )

                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className="sign-heading">
                                        <h5>Sign company documents</h5>
                                        {
                                            syncMsg !== '' && syncMsg !== null ?
                                                <div>{syncMsg}</div>
                                                :
                                                <div>Please sign all documents below so we can pay your team and file your taxes.</div>

                                        }
                                    </div>
                                    {
                                        (syncMsg === '' || syncMsg === null || syncMsg === "") &&
                                        <Table responsive className="mt-4 header_bg_table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            {signDocumentsList?.length !== 0 ? (
                                                <tbody>
                                                    {signDocumentsList?.map((row, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="sign-doc-name" onClick={() => navigate('/sign-documents-details', { state: { row, companyName } })}>
                                                                        {row?.title}
                                                                    </div>
                                                                </td>
                                                                <td>{row?.description}</td>
                                                                <td className="status-column">
                                                                    {
                                                                        !row.requires_signing ?
                                                                            <Badge pill bg="success" className="sign-status sign-complete-status">Completed</Badge>
                                                                            :
                                                                            <Badge pill bg="success" className="sign-status sign-incomplete-status">Incomplete</Badge>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            ) :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="3">
                                                            <h4 className="no-data mb-0">No data found</h4>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </Table>
                                    }



                                </div>
                                :
                                <div className="sign-no-empty-data">
                                    <div className="sign-no-data">
                                        <h4>{missingTitle}</h4>
                                        <div>{missingSubTitle}</div>
                                        <div className="sign-error my-2">
                                            <ul>
                                                {
                                                    missingRequirement?.map((data, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                {
                                                                    data?.link_to === 'add_address' &&
                                                                    <li>
                                                                        <div className="sign-doc-name"
                                                                            onClick={() => navigate('/company-settings', { state: "add_address" })}
                                                                        >
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'federal_tax' &&
                                                                    <li>
                                                                        <div className="sign-doc-name"
                                                                            onClick={() => navigate('/company-settings', { state: "federal_tax" })}
                                                                        >
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'employer-state-tax' &&
                                                                    <li>
                                                                        <div className="sign-doc-name"
                                                                            onClick={() => navigate('/employer-state-tax')}
                                                                        >
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'sign-documents' &&
                                                                    <li>
                                                                        <div className="sign-doc-name same-sign-link">
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'bank_info' &&
                                                                    <li>
                                                                        <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "bank_info" })}>
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'signatory' &&
                                                                    <li>
                                                                        <div className="sign-doc-name" onClick={() => navigate('/signatory')}>
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'industry' &&
                                                                    <li>
                                                                        <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "industry" })}>
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.link_to === 'payroll' &&
                                                                    <li>
                                                                        <div className="sign-doc-name" onClick={() => navigate('/company-settings', { state: "payroll" })}>
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                                {
                                                                    data?.id === 'add_employees' &&
                                                                    <li>
                                                                        <div className="sign-doc-name" onClick={() => window.open(data?.link_to, "_self")}>
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }

                                                                {
                                                                    data?.link_to === '#' &&
                                                                    <li>
                                                                        <div className="sign-doc-name same-sign-link" >
                                                                            {data?.title}
                                                                        </div>
                                                                    </li>
                                                                }
                                                            </React.Fragment>
                                                        )

                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                        }


                    </div>
                </Container >
            )}
            {loading && <Loader />}
        </>
    )
}


export default SignDocuments