import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { isLogedIn, userRole, userDetails } from "../../api";

const AuthCheck = ({ children }) => {
    const navigate = useNavigate();
    const isLoggedIn = isLogedIn();
    const userDetail = userDetails();

    useEffect(() => {
        if (isLoggedIn) {
            if (userRole() === 'Employee') {
                if (userDetail?.onboard_status !== 'completed' && userDetail?.onboard_status !== 'offer_sign') {
                    navigate("/employee-onboard-sections")
                } else if (userDetail?.onboard_status === 'offer_sign') {
                    navigate("/offer-letter")
                } else if (userDetail?.onboard_status === 'completed') {
                    window.open(userDetails()?.dashbaord_url, "_self");
                }
            } else if (userRole() === 'Client') {
                if (userDetail?.onboard_status !== 'completed' && userDetail?.onboard_status !== 'offer_sign') {
                    navigate("/client-onboard-sections")
                } else if (userDetail?.onboard_status === 'offer_sign') {
                    navigate("/offer-letter")
                } else if (userDetail?.onboard_status === 'completed') {
                    window.open(userDetails()?.dashbaord_url, "_self");
                }
            }
        }
    }, [])

    if (!isLoggedIn) {
        return children;
    }

};

export default AuthCheck;