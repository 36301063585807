import React, { memo, useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
const ViewPdf = ({ file, pageNumber, scale, onPdfLoadSuccess }) => {
    const [pageCount, setpageCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(pageNumber);
    function onDocumentLoadSuccess({ numPages }) {
        setpageCount(numPages);
        onPdfLoadSuccess(numPages)
    }
 
    useEffect(() => {
        setCurrentPage(pageNumber);
    }, [pageNumber]);
    return (
        <React.Fragment>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {pageCount &&
                    <Page
                        key={`page_${currentPage}`}
                        pageNumber={currentPage}
                        scale={scale}
                    />
                }
            </Document>
        </React.Fragment>
    )
};
export default memo(ViewPdf);