import React, { useState } from 'react';

const PdfInput = ({ page,inputId, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value || '');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(page,inputId,newValue)
  };
  return (
    <div className="pdf-input">
      <input
        type="text"
        placeholder="Free Text"
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default PdfInput;
