import React, { useState } from 'react';

const PdfCheckBox = ({ page,inputId, value, onChange }) => {
    const [isChecked, setIsChecked] = useState(value || false);

    const handleInputChange = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        onChange(page,inputId,newValue);
    };
    return (
        <div className="pdf-input-checkbox">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default PdfCheckBox;
