import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { FiHelpCircle } from 'react-icons/fi';
import { postData, timeZoneOffset, convertUTCToLocalDate } from "../../../api";
import { toast } from "react-toastify";
import moment from 'moment'
import Loader from "../../../components/Loader";
import {autocloseTiming} from '../../../api/regex';

const PayrollScheme = ({ nextStep, onboardObject, payFrequencyList, firstPayList, secondPayList }) => {

    const focusDate = useRef(null);
    const endFocusDate = useRef(null);
    const deadlineFocusDate = useRef(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        type: "",
        first_pay_day: "",
        second_pay_day: "",
        firstphase: "",
        secondphase: "",
        acknowledge: false,
        deadlineDate: new Date()
    });
    const [errors, setErrors] = useState({});
    const [dateInputs, setDateInputs] = useState({
        firstphase: null,
        secondphase: null,
    });


    useEffect(() => {
        if (onboardObject?.payroll) {
            setClientObject(onboardObject?.payroll)
            setClientObject((prev) => ({
                ...prev,
                acknowledge: true,
            }));
            setDateInputs({
                // firstphase: moment.utc(onboardObject?.payroll.firstphase).add(timeZoneOffset, 'minutes').toDate(),
                // secondphase: moment.utc(onboardObject?.payroll.secondphase).add(timeZoneOffset, 'minutes').toDate()
                // firstphase: new Date(onboardObject?.payroll.firstphase),
                // secondphase: new Date(onboardObject?.payroll.secondphase),

                firstphase: convertUTCToLocalDate(onboardObject?.payroll.firstphase),
                secondphase: convertUTCToLocalDate(onboardObject?.payroll.secondphase),


            });
        }
    }, [onboardObject]);

    useEffect(() => {
        if (dateInputs.firstphase !== null) {
            let deadlineDate = new Date(dateInputs?.firstphase?.getTime());
            deadlineDate.setDate(dateInputs.firstphase?.getDate() - 2);
            // Applying timeZoneOffset to deadlineDate
            deadlineDate.setMinutes(deadlineDate.getMinutes() + timeZoneOffset);
            setClientObject((prev) => ({
                ...prev,
                deadlineDate: deadlineDate,
            }));
        }
    }, [dateInputs]);

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setClientObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };

    const handleCheckAcknowledgeChange = (e) => {
        const name = e.target.name;
        if (e.target.checked === true) {
            setClientObject((prev) => ({
                ...prev,
                acknowledge: true,
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    acknowledge: null,
                });
        } else {
            setClientObject((prev) => ({
                ...prev,
                acknowledge: false,
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    acknowledge: null,
                });
        }
    };


    const renderTooltip =
        <div>
            The last date of the first pay period to help calculate future pay periods. This can be the same date as the first pay date.
        </div>


    const handleEndFocusDate = () => {
        const datepickerElement = endFocusDate.current;
        datepickerElement.setFocus(true);
    };

    const handleFocusDate = () => {
        const datepickerElement = focusDate.current;
        datepickerElement.setFocus(true);
    };

    const handleDeadlineFocusDate = () => {
        const datepickerElement = deadlineFocusDate.current;
        datepickerElement.setFocus(true);
    };

    const findFormErrors = () => {

        const { type, first_pay_day, second_pay_day, firstphase, secondphase, acknowledge } = clientObject;
        let newErrors = {};
        if (!type || type === "") newErrors.type = "Please enter pay frequency.";
        if (type === 'semimonthly custom') {
            if (!first_pay_day || first_pay_day === "" || first_pay_day === null) newErrors.first_pay_day = "Please select  month.";
            if (!second_pay_day || second_pay_day === "" || second_pay_day === null) newErrors.second_pay_day = "Please select month.";
        }
        if (!firstphase || firstphase === "" || firstphase === null) newErrors.firstphase = "Please enter your first pay date.";
        if (!secondphase || secondphase === "" || secondphase === null) newErrors.secondphase = "Please enter your first pay period end date.";
        else if (type === 'weekly') {
            // 10days future current date including so 10 days
            // current date also included

            let validDate = moment.utc(secondphase);
            // Add 10 days to the date
            validDate.add(10, 'days');
            let checkDate = validDate.format('DD/MM/YYYY');

            // three dates to compare
            var d1 = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");
            var d2 = checkDate.split("/");
            var c = moment.utc(new Date(firstphase)).format("DD/MM/YYYY").split("/");

            var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
            var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
            var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

            if (!(check >= from && check <= to)) {
                newErrors.firstphase = "The end of the pay period may not be more than 10 days before the pay day.";
            }
        }
        else if (type === 'biweekly') {
            // 17days future current date including so 17 days
            // current date also included

            let validDate = moment.utc(secondphase);
            // Add 10 days to the date
            validDate.add(17, 'days');
            let checkDate = validDate.format('DD/MM/YYYY');

            // three dates to compare
            var d1 = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");
            var d2 = checkDate.split("/");
            var c = moment.utc(new Date(firstphase)).format("DD/MM/YYYY").split("/");

            var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
            var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
            var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

            if (!(check >= from && check <= to)) {
                newErrors.firstphase = "The end of the pay period may not be more than 17 days before the pay day.";
            }
        }
        else if (type === 'semimonthly') {
            // based on firstphase below date comparison done
            let validDate = moment.utc(new Date(firstphase));
            let validDateFormat = moment.utc(new Date(firstphase)).format("DD/MM/YYYY");
            let firstphaseDate = moment.utc(new Date(firstphase)).format("DD/MM/YYYY");

            let lastMonthDate15;
            let lastDateofMonth;
            // when I selected the 15th of the month lastDateofMonth date should be last month of end date
            // ex: 15/8/2023 means lastDateofMonth = 31/7/2033
            if (validDateFormat === validDate.clone().date(15).format("DD/MM/YYYY")) {
                lastMonthDate15 = validDate.clone().date(15).format("DD/MM/YYYY");
                lastDateofMonth = validDate.clone().subtract(1, 'months').endOf('month').format("DD/MM/YYYY");
            } else {
                lastMonthDate15 = validDate.clone().date(15).format("DD/MM/YYYY");
                lastDateofMonth = validDate.clone().endOf('month').format("DD/MM/YYYY");
            }
            // firstphaseDate should be 15th or last date of the month

            if (firstphaseDate === lastMonthDate15 || firstphaseDate === lastDateofMonth) {
                if (firstphaseDate === lastMonthDate15) {
                    // secondphase (end date) date should be 31/7/2033 to 15/8/2023 when firstphaseDate is 15/8/2023   
                    // three dates to compare
                    var d1 = lastDateofMonth.split("/");
                    var d2 = lastMonthDate15.split("/");
                    var c = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");

                    var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
                    var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
                    var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

                    if (!(check >= from && check <= to)) {
                        newErrors.firstphase = "The end of the pay period may not be before the preceding pay day.";
                    }

                }
                else if (firstphaseDate === lastDateofMonth) {
                    // secondphase (end date) date should be 15/8/2033 to 31/8/2023 when firstphaseDate is 31/8/2023

                    // three dates to compare
                    var d1 = lastMonthDate15.split("/");
                    var d2 = lastDateofMonth.split("/");
                    var c = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");

                    var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
                    var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
                    var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

                    if (!(check >= from && check <= to)) {
                        newErrors.firstphase = "The end of the pay period may not be before the preceding pay day.";
                    }
                }
            }
            else {
                newErrors.firstphase = "The first payday you selected is incompatible with the dates of pay.";
            }


        }

        else if (type === 'semimonthly custom') {
            // based on firstphase below date comparison done
            let validDate = moment.utc(new Date(firstphase));
            let validDateFormat = moment.utc(new Date(firstphase)).format("DD/MM/YYYY");
            let firstphaseDate = moment.utc(new Date(firstphase)).format("DD/MM/YYYY");

            let firstPayMonthDate = validDate.clone().date(first_pay_day).format("DD/MM/YYYY");
            let secondPayMonthDate = validDate.clone().date(second_pay_day).format("DD/MM/YYYY");

            let difference = second_pay_day - first_pay_day;

            let lastMonthSecondPayMonthDate = validDate.clone().date(second_pay_day).subtract(1, 'months').format("DD/MM/YYYY");


            if (difference < 13) {
                newErrors.second_pay_day = "For a semimonthly pay period, the first and second pay days cannot be less than 13 days apart.";
            } else if (!(difference >= 13 && difference <= 17)) {
                newErrors.second_pay_day = "For a semimonthly pay period, the first and second pay days cannot be more than 17 days apart.";
            }
            if (firstphaseDate === firstPayMonthDate || firstphaseDate === secondPayMonthDate) {
                if (firstphaseDate === firstPayMonthDate) {
                    // firstPayMonthDate = 5
                    // secondPayMonthDate = 20 
                    // secondphase (end date) date should be 20/7/2033 to 5/8/2023 when firstphaseDate is 5/8/2023   

                    // three dates to compare
                    var d1 = lastMonthSecondPayMonthDate.split("/");
                    var d2 = firstPayMonthDate.split("/");
                    var c = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");

                    var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
                    var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
                    var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

                    if (!(check >= from && check <= to)) {
                        newErrors.firstphase = "The end of the pay period may not be before the preceding pay day.";
                    }

                }
                else if (firstphaseDate === secondPayMonthDate) {
                    // firstPayMonthDate = 5
                    // secondPayMonthDate = 20 
                    // secondphase (end date) date should be 5/8/2033 to 20/8/2023 when firstphaseDate is 20/8/2023

                    // three dates to compare
                    var d1 = firstPayMonthDate.split("/");
                    var d2 = secondPayMonthDate.split("/");
                    var c = moment.utc(new Date(secondphase)).format("DD/MM/YYYY").split("/");

                    var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
                    var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
                    var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

                    if (!(check >= from && check <= to)) {
                        newErrors.firstphase = "The end of the pay period may not be before the preceding pay day.";
                    }
                }
            }
            else {
                newErrors.firstphase = "The first payday you selected is incompatible with the dates of pay.";
            }


        }
        else if (type === 'monthly') {
            let validDate = moment.utc(dateInputs?.secondphase);
            let lastDateofMonth = validDate.clone().endOf('month').format("DD/MM/YYYY");
            var firstphaseDate = moment.utc(new Date(firstphase)).format("DD/MM/YYYY");
            if (firstphaseDate !== lastDateofMonth) {
                newErrors.firstphase = "The first pay day you selected is incompatible with the date of pay.";
            }
        }
        if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            payrollScheme();
        }
    };

    const payrollScheme = async () => {
        setLoading(true);
        const AddObject = structuredClone(clientObject);
        AddObject.firstphase = moment(clientObject.firstphase).format("YYYY-MM-DD");
        let data = {
            stage: "payroll",
            ...AddObject
        }
        setLoading(true);
        const res = await postData("client_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            nextStep(res.data.status);
            setErrors({})
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const minTwoDigit = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    const formatDates = (date) => {
        const day = minTwoDigit(date.getDate());
        const month = minTwoDigit(date.getMonth() + 1);
        const year = date.getFullYear();
        let val = `${year}-${month}-${day}`;
        return val;
    };

    const selectUploadDt = (dateSel, name) => {
        const date = new Date(`${dateSel}`);
        const chkDt = date.getDate();

        if (chkDt > 0) {
            let val = formatDates(date);
            setClientObject((prev) => ({
                ...prev,
                [name]: val
            }));
            setDateInputs((prev) => ({
                ...prev,
                [name]: dateSel,
            }));
            // setDateInputs((prev) => ({
            //     ...prev,
            //     [name]: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
            // }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    [name]: null,
                });
        }

    };


    //diable past days in first pay date
    const today = new Date();
    today.setHours(0, 0, 0, 0);


    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections payroll-client">
                    <h5 className="mb-3"> Payroll details</h5>

                    <div className="payroll-sub-title">
                        Why do we need to ask for this? We need to know when to pay your employees. Some states have <a href="https://www.dol.gov/agencies/whd/state/payday" target="_blank" rel="noopener noreferrer">laws around when you must pay your employees. </a> Please choose pay schedules that are legal for your employees.
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form my-3">
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Pay frequency <span className="asterisk">*</span>  </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="type"
                                        value={clientObject.type}
                                        required
                                        isInvalid={!!errors.type}
                                        size="lg"
                                    >
                                        <option value="">Select pay frequency</option>
                                        {payFrequencyList &&
                                            payFrequencyList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4} className={`${clientObject.type === "semimonthly custom" ? "d-block" : 'd-none'}`}>
                                <Form.Group className="mb-3" controlId="first_pay_day">
                                    <Form.Label>First pay day of month <span className="asterisk">*</span> </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="first_pay_day"
                                        value={clientObject.first_pay_day}
                                        required
                                        size="lg"
                                    >
                                        <option value="">Select month</option>
                                        {firstPayList &&
                                            firstPayList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors?.first_pay_day}</div>
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={4} className={`${clientObject.type === "semimonthly custom" ? "d-block" : 'd-none'}`}>
                                <Form.Group className="mb-3" controlId="second_pay_day">
                                    <Form.Label>Second pay day of month <span className="asterisk">*</span> </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="second_pay_day"
                                        value={clientObject.second_pay_day}
                                        required
                                        size="lg"
                                    >
                                        <option value="">Select month</option>
                                        {secondPayList &&
                                            secondPayList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors?.second_pay_day}</div>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="card_container row">
                            <div className="cards">
                                <Form.Group className="position-relative mb-3" controlId="firstphase">
                                    <Form.Label>First pay date <span className="asterisk">*</span> </Form.Label>
                                    <DatePicker
                                        ref={focusDate}
                                        className="datepicker-payscheme"
                                        selected={dateInputs?.firstphase ? dateInputs?.firstphase : null}
                                        onChange={(date) =>
                                            selectUploadDt(date, "firstphase")
                                        }
                                        name="firstphase"
                                        dateFormat="MM-dd-yyyy"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        calendarIcon={true}
                                        closeCalendar={true}
                                        clearIcon={true}
                                        minDate={today}
                                    />
                                    <span className="calendar-icon" onClick={handleFocusDate}></span>
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors?.firstphase}</div>
                                    }
                                </Form.Group>
                            </div>
                            <div className="cards">
                                <Form.Group className="position-relative mb-3" controlId="deadlineDate">
                                    <Form.Label>Deadline to run payroll </Form.Label>
                                    <DatePicker
                                        ref={deadlineFocusDate}
                                        className="datepicker-payscheme"
                                        selected={clientObject.deadlineDate}
                                        name="deadlineDate"
                                        dateFormat="MM-dd-yyyy"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        calendarIcon={true}
                                        closeCalendar={true}
                                        clearIcon={true}
                                        disabled
                                    />
                                    <span className="calendar-icon" onClick={handleDeadlineFocusDate}></span>
                                </Form.Group>
                            </div>
                            <div className="cards">
                                <Form.Group className="position-relative mb-3" controlId="secondphase">
                                    <Form.Label className="me-2">First pay period end date <span className="asterisk">*</span> </Form.Label>
                                    <Tooltip placement="left" overlay={renderTooltip} overlayClassName="pay-tooltip">
                                        <FiHelpCircle className="help-circle" />
                                    </Tooltip>
                                    <DatePicker
                                        ref={endFocusDate}
                                        className="datepicker-payscheme"
                                        selected={dateInputs?.secondphase ? dateInputs?.secondphase : null}
                                        onChange={(date) =>
                                            selectUploadDt(date, "secondphase")
                                        }
                                        name="secondphase"
                                        dateFormat="MM-dd-yyyy"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        calendarIcon={true}
                                        closeCalendar={true}
                                        clearIcon={true}
                                    />
                                    <span className="calendar-icon" onClick={handleEndFocusDate}></span>
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors?.secondphase}</div>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                        <Row className="check-custom mt-5">
                            <Col md={12}>
                                <Form.Group className="mb-3 bank-check" controlId="acknowledge">
                                    <Form.Check
                                        label="I acknowledge i won’t be able to run payroll for up to 2 business days until the bank verification completes."
                                        name="acknowledge"
                                        type="checkbox"
                                        checked={clientObject.acknowledge}
                                        value="1"
                                        onChange={(e) => handleCheckAcknowledgeChange(e)}
                                        id="acknowledgment"
                                        required
                                    />
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors.acknowledge}</div>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => nextStep("bank_info")}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div>

                    </Form>
                </div>

            )}
            {loading && <Loader />}

        </>
    )
}

export default PayrollScheme