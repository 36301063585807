import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import OnboardHeading from "../../../components/OnboardHeading";
import HeaderForm from "../../../components/HeaderForm";
import { getRevertUser } from "../../../api";
import Loader from "../../../components/Loader";
import revertIcon from "../../../assets/images/fa_refresh.svg";
import { useLocation } from 'react-router-dom';
import PdfViewer from "../../../components/CustomPdf/pdf";

const TaskCustomPdf = () => {

    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const pdfFullData = state?.document;
    const pdfData = state?.document?.custom_documents;
    const companyName = localStorage.getItem("company_name")

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            <HeaderForm />
            <div className="profile_container">
                <Row>
                    <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                        <OnboardHeading title="Complete Client Document" subtitle={companyName} />
                    </Col>
                    {
                        getRevertUser() ?
                            <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                <div className="revert">
                                    <Button
                                        variant="danger"
                                        className="revert_btn"
                                        onClick={handleRevertUser}
                                    >
                                        <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                        Revert User
                                    </Button>
                                </div>
                            </Col>
                            : ''
                    }
                </Row>
                {!loading && (
                    <div className="create-board">
                        <div className="board-container">
                            <div className="pdf-settings">
                                <PdfViewer file={pdfData?.document_url} pdfData={pdfData} pdfFullData={pdfFullData} pageType="profile_task" />
                            </div>
                        </div>
                    </div>
                )}
                {loading && <Loader />}
            </div>
        </>
    );
};

export default TaskCustomPdf;
