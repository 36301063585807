import React from "react";
import "./style.scss";


const DashBoard = () => {



  return (
    <>
      <h3 className="page_title">Dashboard</h3>
    </>
  );
};

export default DashBoard;
