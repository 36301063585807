
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { Collapse } from 'react-bootstrap';
import logo from "../../assets/images/logo-img-2.svg";
import sideExpand from '../../assets/images/side_expand.png'
import arrowRight from '../../assets/images/arrow_right_new.svg'
import faviconLogo from "../../assets/images/logo_small.svg";
import sideClose from '../../assets/images/side_close.png';
import DashboardSvg from '../../assets/images/sidebar_icons/dashboard.svg'
import ProfileSvg from '../../assets/images/sidebar_icons/profile.svg'
import DocumentSvg from '../../assets/images/sidebar_icons/document.svg'
import MyTeamSvg from '../../assets/images/sidebar_icons/team.svg'
import Annoncement from '../../assets/images/sidebar_icons/annoncement.svg'
import Reports from '../../assets/images/sidebar_icons/reports.svg'
import InviteReseller from '../../assets/images/sidebar_icons/invite_reseller.svg'
import TimeKeepingSvg from '../../assets/images/sidebar_icons/time_keeping_svg.svg'
import GoalKeeping from '../../assets/images/sidebar_icons/goalKeeping.svg'
import subscriptionPlans from '../../assets/images/sidebar_icons/subscriptionPlans.svg'
import settings from '../../assets/images/sidebar_icons/settings.svg'
import logout from '../../assets/images/sidebar_icons/logout.svg'
import InviteClient from '../../assets/images/sidebar_icons/invite_client.svg'
import ManageClients from '../../assets/images/sidebar_icons/manage_clients.svg'
import ManageUsers from '../../assets/images/sidebar_icons/manage_users.svg'
import timeCard from '../../assets/images/sidebar_icons/timeCard.svg'
import customPermissions from '../../assets/images/sidebar_icons/custom_permissions.svg'
import employeeStatus from '../../assets/images/sidebar_icons/employe_status.svg'
import payroll from '../../assets/images/sidebar_icons/payroll.svg'


const Sidebar = ({ sideMenus }) => {

  const [open, setOpen] = useState(false);
  const [sidebarExpand, setSidebarExpand] = useState(false);
  const [isSidebarExpand, setIsSidebarExpand] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [clientName, setClientName] = useState(null)

  const companyLogo = (sideMenus?.userInfo?.company_logo !== undefined || sideMenus?.userInfo?.company_logo !== null || sideMenus?.userInfo?.company_logo !== '') && sideMenus?.userInfo?.company_logo
  const favCompanyLogo = (sideMenus?.userInfo?.company_logo !== undefined || sideMenus?.userInfo?.company_logo !== null || sideMenus?.userInfo?.company_logo !== '') && sideMenus?.userInfo?.company_logo


  useEffect(() => {
    if (sidebarExpand) {
      document.body.classList.add('minimize-sidebar');
      setIsSidebarExpand(true)
      setOpen(false)

    } else {
      document.body.classList.remove("minimize-sidebar")
      setIsSidebarExpand(false)
    }
  }, [sidebarExpand])

  useEffect(() => {
    if (document.body.classList.contains('minimize-sidebar')) {
      setIsSidebarExpand(true)
    } else {
      setIsSidebarExpand(false)
    }

  })
  useEffect(() => {
    if (dropOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [dropOpen])


  const handleExpand = () => {
    setSidebarExpand(!sidebarExpand)
  }

  const handleLinkClick = () => {
    localStorage.removeItem("profile_id");
  }

  const currentUrl = window.location.href;
  const urlLastWord = "/" + currentUrl.split("/").pop()

  useEffect(() => {
    let name = sideMenus?.userInfo?.client_name.toUpperCase()
    setClientName(name)
  }, [sideMenus?.userInfo?.client_name]);


  return (
    <>
      {sideMenus.length !== 0 && (
        <div div className={`${isSidebarExpand ? 'close-sidebar sidebar' : 'sidebar'}`}>
          <nav className="sidebar-nav">

            <div className="expand-logo py-2 desk-view logo-icon-new">
              {
                sidebarExpand ?
                  <div className="new-fav-test ">
                    {favCompanyLogo ?
                      <img src={favCompanyLogo} alt="logo" className="max-fav-logo-new" /> :
                      <div className="max-fav-logo-new color_set mob_size">
                        {clientName}
                      </div>
                    }
                  </div> :
                  <>
                    <div className="desk-logo">
                      {companyLogo ?
                        <img src={companyLogo} alt="logo" className="max-logo-new" /> :
                        <div className="max-logo-new color_set web_size">
                          {clientName}
                        </div>
                      }
                    </div>

                  </>
              }
            </div>
            <div className="desk-view side-expand" >
              <img src={`${sidebarExpand ? sideClose : sideExpand}`} alt="side expand" onClick={handleExpand} />
            </div>

            <ul>
              {
                sideMenus?.sidebar?.map((item, i) => (
                  <React.Fragment key={i}>
                    {
                      item?.children?.length ?
                        <>
                          <li title={item.name}>
                            <a onClick={() => setOpen(!open)} className={({ isActive }) => (isActive ? "active" : "")}>
                              <div className="nav-new-icon">
                                {item.name == "Company settings" &&
                                  <img src={settings} alt="side-icon" />
                                }
                              </div>
                              <div className={`new-nav-text ${sidebarExpand ? 'd-none' : 'd-block'}`} >{item.name} </div>
                            </a>

                          </li>
                          <Collapse in={open}>
                            <div id="example-collapse-text" className="sub-menu" >
                              {
                                item?.children.map((submenu, i) => (
                                  <li key={i} title={submenu.name}>
                                    <NavLink
                                      to={submenu.link}
                                      className={({ isActive }) => (isActive ? `active ${setDropOpen(true)}` : "")}

                                    >
                                      <div className="sub-icon">
                                        <img src={arrowRight} alt="arrow" className="sub-menu-icon" />
                                      </div>
                                      <div className={`submenu-text ${sidebarExpand ? 'd-none' : 'd-block'}`}>{submenu.name} </div>
                                    </NavLink>

                                  </li>
                                ))
                              }
                            </div>

                          </Collapse>
                        </>
                        :
                        <li >

                          <a href={item?.link} className={`${urlLastWord === item.link ? "active" : ""}`} title={item.name} onClick={() => handleLinkClick()}>
                            <div className="nav-new-icon">
                              {item.name === "Dashboard" &&
                                <img src={DashboardSvg} alt="side-icon" />
                              }
                              {item.name === "Profile" &&
                                <img src={ProfileSvg} alt="side-icon" />
                              }
                              {item.name === "My Team" &&
                                <img src={MyTeamSvg} alt="side-icon" />
                              }
                              {item.name === "Documents" &&
                                <img src={DocumentSvg} alt="side-icon" />
                              }
                              {item.name === "Announcements" &&
                                <img src={Annoncement} alt="side-icon" />
                              }
                              {item.name === "Reports" &&
                                <img src={Reports} alt="side-icon" />
                              }
                              {item.name === "Timekeeping" &&
                                <img src={TimeKeepingSvg} alt="side-icon" />
                              }
                              {item.name === "Invite Client" &&
                                <img src={InviteClient} alt="side-icon" />
                              }

                              {item.name === "Invite Reseller" &&
                                <img src={InviteReseller} alt="side-icon" />
                              }
                              {item.name === "Manage Clients" &&
                                <img src={ManageClients} alt="side-icon" />
                              }
                              {item.name === "Manage Users" &&
                                <img src={ManageUsers} alt="side-icon" />
                              }
                              {item.name === "Goal Tracking" &&
                                <img src={GoalKeeping} alt="side-icon" />
                              }
                              {item.name === "Subscription Plans" &&
                                <img src={subscriptionPlans} alt="side-icon" />
                              }
                              {item.name === "Timecard" &&
                                <img src={timeCard} alt="side-icon" />
                              }
                              {item.name === "Custom Permission" &&
                                <img src={customPermissions} alt="side-icon" />
                              }
                              {item.name === "Emp payroll status" &&
                                <img src={employeeStatus} alt="side-icon" />
                              }
                              {item.name === "Payroll" &&
                                <img src={payroll} alt="side-icon" />
                              }
                              {item.name === "Logout" &&
                                <img src={logout} alt="side-icon" />
                              }
                            </div>
                            <div className={`new-nav-text ${sidebarExpand ? 'd-hide' : 'd-block'}`} >{item.name} </div>
                          </a>

                        </li>
                    }
                  </React.Fragment>
                ))
              }
            </ul>
          </nav>

        </div>
      )}
    </>
  );
};
export default Sidebar;
