import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ClientOnboarding from "./pages/ClientOnboarding";
import ClientOnboardingSections from "./pages/ClientOnboarding/ClientOnboardingForms";
import LoginLayout from './layout/LoginLayout';
import AdminLayout from './layout/AdminLayout';
import Layout from "./layout/LayoutWithSidebar";
import EmployeeOnboarding from "./pages/EmployeeOnboarding";
import SetAuth from "./pages/SetAuth";
import EmployeeOnboardingSections from "./pages/EmployeeOnboarding/EmployeeOnboardingForms";
import Offerletter from "./pages/Offerletter";
import DashBoard from "./pages/Dashboard";
import "./App.scss";
import CompanySettings from "./pages/CompanySettings";
import Logout from "./layout/Logout";
import Profile from "./pages/Profile";
import Signatory from "./pages/Signatory";
import EmployerStateTax from "./pages/EmployerStateTax";
import Payroll from "./pages/Payroll";
import NotFound from './layout/NotFound';
import SignDocuments from "./pages/SignDocuments";
import SignDocumentsDetails from "./components/SignDocumentDetails";
import EmployeeStatus from './pages/EmployeeStatus'
import EmployeeCustomPdf from './pages/EmployeeOnboarding/EmployeeOnboardingForms/EmployeeCustomPdf'
import TaskCustomPdf from "./pages/Profile/Tasks/TaskCustomPdf";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<LoginLayout />} >
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" exact={true} element={<Login />} />
            <Route
              path="/forgot-password"
              exact={true}
              element={<ForgotPassword />}
            />
            <Route
              path="/reset-password/:id"
              exact={true}
              element={<ResetPassword />}
            />
            <Route
              path="/client-onboard/:id"
              exact={true}
              element={<ClientOnboarding />}
            />
            <Route
              path="/employee-onboard/:id"
              exact={true}
              element={<EmployeeOnboarding />}
            />
          </Route>
          <Route path="/" exact={true} element={<AdminLayout />} >
            <Route
              path="/client-onboard-sections"
              exact={true}
              element={<ClientOnboardingSections />}
            />
            <Route
              path="/employee-onboard-sections"
              exact={true}
              element={<EmployeeOnboardingSections />}
            />
            <Route
              path="/offer-letter"
              exact={true}
              element={<Offerletter />}
            />
            <Route path="/employee-custom-pdf" exact={true} element={<EmployeeCustomPdf />} />

          </Route>
          <Route path="/" exact={true} element={<Layout />} >
            <Route
              path="/dashboard"
              exact={true}
              element={<DashBoard />}
            />
            <Route
              path="/company-settings"
              exact={true}
              element={<CompanySettings />}
            />
            <Route
              path="/profile"
              exact={true}
              element={<Profile />}
            />
            <Route
              path="/profile/:id"
              exact={true}
              element={<Profile />}
            />
            <Route
              path="/profile/:notification"
              element={<Profile />}
            />
            <Route path="/signatory" exact={true} element={<Signatory />} />
            <Route path="/employer-state-tax" exact={true} element={<EmployerStateTax />} />
            <Route path="/payroll" exact={true} element={<Payroll />} />
            <Route path="/task-custom-pdf" exact={true} element={<TaskCustomPdf />} />
            <Route path="/sign-documents" exact={true} element={<SignDocuments />} />
            <Route path="/sign-documents-details" exact={true} element={<SignDocumentsDetails />} />
            <Route path="/employee-status" exact={true} element={<EmployeeStatus />} />
            
            
          </Route>
          <Route
            path="/set-auth/:data"
            exact={true}
            element={<SetAuth />}
          />
          <Route
            path="/logout"
            exact={true}
            element={<Logout />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
