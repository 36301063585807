import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Button } from "react-bootstrap";
import Buttons from "../../components/Form/Button";
import { Col, Row, Container } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import ServerSideTable from "../../components/ServerSideTable";
import FormModal from "../../components/FormModal";
import Badge from 'react-bootstrap/Badge';
import revertIcon from "../../assets/images/fa_refresh.svg";
import { Markup } from "interweave";
import { autocloseTiming } from '../../api/regex';

const Payroll = () => {

    const [loading, setLoading] = useState(false);
    const [runPaytab, setRunPaytab] = useState(false);
    const [runPayrollLink, setRunPayrollLink] = useState('');
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizeperPage] = useState(25);
    const [totalSize, setTotalSize] = useState(0);
    const [processPayrollList, setProcessPayrollList] = useState([]);
    const [showWaitModal, setShowWaitModal] = useState(false);
    const [showPreviousWaitModal, setShowPreviousWaitModal] = useState(false);
    const [disable, setDisable] = useState(true);
    const [payrollDisable, setPayrollDisable] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [payrollErrors, setPayrollErrors] = useState('');

    const [auto_refresh, setAuto_refresh] = useState(false);

    const handleShowWaitModalOpen = () => {
        setShowWaitModal(true);
    };
    const handleShowWaitModalClose = () => {
        setShowWaitModal(false);
    };

    const handleShowPreviousWaitModalOpen = () => {
        setShowPreviousWaitModal(true);
    };
    const handleShowPreviousWaitModalClose = () => {
        setShowPreviousWaitModal(false);
    };

    useEffect(() => {
        getProcessPayrollList(page, sizePerPage, "");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getProcessPayrollList = async (page, sizePerPage, search) => {
        setLoading(true);
        let obj = {
            page: page,
            page_size: sizePerPage,
            search: search
        };
        try {
            const res = await postData("get-payroll-history", {}, obj);
            if (res?.status === true) {
                setProcessPayrollList(res?.data);
                setTotalSize(res?.count);
                setPayrollDisable(res?.payroll_btn_status)
                setDisable(res?.run_payroll_hide);
                setCompanyName(res?.company_name);
                setPayrollErrors(res?.onboarding_errors);
                setAuto_refresh(res?.auto_refresh);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message, { theme: "colored" });
            }
        } catch (err) { }
    };

    // auto refresh api call for payroll data

    const getAutoRefreshProcessPayrollList = async (page, sizePerPage, search) => {
        setLoading(true);
        let obj = {
            page: page,
            page_size: sizePerPage,
            search: search
        };
        try {
            const res = await postData("get-payroll-history", {}, obj);
            if (res?.status === true) {
                setAuto_refresh(res?.auto_refresh);
                // if (res?.auto_refresh) {
                getProcessPayrollList(page, sizePerPage, "");
                setLoading(false);
                // }
            } else {
                toast.error(res?.message, { theme: "colored" });
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
   

    useEffect(() => {
        getAutoRefreshProcessPayrollList(page, sizePerPage, '');

        // Set up the interval to make the API call every 30 seconds
        const interval = setInterval(() => {
            if (auto_refresh) {
                getAutoRefreshProcessPayrollList(page, sizePerPage, '');
            } else {
                clearInterval(interval);
            }
        }, 30000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [page, sizePerPage, auto_refresh]); // eslint-disable-line react-hooks/exhaustive-deps
    
    // auto refresh api call for payroll data end
    const processPayroll = async () => {
        setLoading(true);
        try {
            const res = await postData("start-process-payroll", {});
            if (res?.status === true) {
                if (res?.new_entry) {
                    handleShowWaitModalOpen();
                    getProcessPayrollList(page, sizePerPage, "");
                } else {
                    if (res?.is_payroll_running) {
                        handleShowPreviousWaitModalOpen()
                    } else {
                        toast.error(res?.message, { theme: "colored" });
                    }
                }
                setLoading(false);
            }
        } catch (err) { }
    }

    const runPayroll = () => {
        setRunPaytab(true);
        getRunPayroll();
    }

    const getRunPayroll = async () => {
        setLoading(true);
        const res = await postData("run-payroll-flow", {});
        if (res.status === true) {
            setLoading(false);
            setRunPayrollLink(res?.data?.url)
            toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };


    const payrollStatusFormatter = (cell, row) => {
        return (
            <>
                {
                    row.payroll_status === 'pending' ?
                        <Badge pill bg="success" className="pay-pending-status">  {row.payroll_status} </Badge>
                        : row.payroll_status === 'failed' ?
                            <Badge pill bg="success" className="pay-incomplete-status">  {row.payroll_status} </Badge>
                            : <Badge pill bg="success" className="pay-complete-status">  {row.payroll_status} </Badge>
                }
            </>
        )
    }

    const columns = [
        {
            dataField: "sl.no",
            text: "ID",
            headerStyle: { width: "5%", textAlign: "left" },
            style: { textAlign: "left" },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
            }
        },
        {
            dataField: "name",
            text: "Processed by",
            headerStyle: { width: "20%", textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "payroll_processing_date",
            text: "Processed Date",
            headerStyle: { width: "20%", textAlign: "center" },
            style: { textAlign: "center" }
        },
        {
            dataField: "payroll_status",
            text: "Status",
            headerStyle: { width: "16%", textAlign: "left" },
            style: { textAlign: "left" },
            formatter: payrollStatusFormatter,
        }
    ];
    const onFilter = (page, sizePerPage, search) => {
        setPage(page);
        setSizeperPage(sizePerPage);
        getProcessPayrollList(page, sizePerPage, search);
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            {!loading && (
                <Container fluid className="new_payroll">
                    <div className="paystub-view">
                        <Row>
                            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                                <OnboardHeading title="Payroll" subtitle={companyName} />
                            </Col>
                            {
                                getRevertUser() ?
                                    <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                        <div className="revert">
                                            <Button
                                                variant="danger"
                                                className="revert_btn"
                                                onClick={handleRevertUser}
                                            >
                                                <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                                Revert User
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>
                        <div className={`${runPaytab ? ' d-none' : 'd-block'}`}>
                            <div className="text-start">
                                <Buttons
                                    type="button"
                                    className="btn-primary text-white pay-btn me-2"
                                    onClick={() => processPayroll()}
                                    disabled={!payrollDisable}
                                >
                                    Process payroll
                                </Buttons>
                                <Buttons
                                    type="button"
                                    className="btn-primary text-white run-btn"
                                    onClick={() => runPayroll()}
                                    disabled={disable}
                                >
                                    Run payroll
                                </Buttons>
                            </div>
                        </div>
                        <div>
                            <Markup
                                content={payrollErrors}
                            />
                        </div>
                        {/* <div class="payroll-warning my-3">
                            <div class="payroll-warning-message">
                                <h4>Payroll Processing Alert!</h4>
                                <div>
                                    Payroll cannot be processed at this time due to missing requirements. Please complete The following tasks to proceed:
                                    <ul>
                                        <li>State tax</li>
                                        <li>Signatoary</li>
                                    </ul>
                                </div>
                                <div>
                                    For assistance, please contact our support team at [contact info].
                                </div>
                            </div>
                        </div>  */}
                        <div className={`process-payroll my-3 ${runPaytab ? ' d-none' : 'd-block'}`}>
                            <ServerSideTable
                                columns={columns}
                                data={processPayrollList}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                onFilter={onFilter}
                                loading={loading}
                            >
                            </ServerSideTable>
                        </div>
                        <div className={runPaytab ? ' d-block' : 'd-none'}>
                            <div className="emp-state-tax-view">
                                <iframe src={runPayrollLink} name="emp-state-tax-view" height="100%" width="100%" title="Iframe Example"></iframe>
                            </div>
                        </div>
                        <div>
                            {showWaitModal && (
                                <FormModal heading="Attention" show={showWaitModal} size="md" onClose={handleShowWaitModalClose} backdrop="static" modalClassName="personal_container_modal">
                                    <Row>
                                        <Col md={12}>
                                            This may take some time. Please wait!
                                        </Col>
                                    </Row>
                                </FormModal>
                            )}

                            {showPreviousWaitModal && (
                                <FormModal heading="Attention" show={showPreviousWaitModal} size="md" onClose={handleShowPreviousWaitModalClose} backdrop="static" modalClassName="personal_container_modal">
                                    <Row>
                                        <Col md={12}>
                                            Your previous request is still in progress! This may take some more time. Please wait
                                        </Col>
                                    </Row>
                                </FormModal>
                            )}
                        </div>
                    </div>
                </Container>
            )}
            {loading && <Loader />}


        </>
    )
}

export default Payroll