import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import InputGroup from 'react-bootstrap/InputGroup';
import { mustNumberswithPhone, onlyCharacterWithSpace, emptySpace, onlyCharacterWithSpaceAndComma, autocloseTiming } from "../../../api/regex";
import { postData, phoneNumberFormat } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const EmergencyContact = ({ nextStep, onboardObject, relationshipList }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        pc_fullname: "",
        pc_relation: "",
        pc_other: "",
        pc_phone: "",
        pc_alternate_phone: "",
        // 
        sc_fullname: "",
        sc_relation: "",
        sc_other: "",
        sc_phone: "",
        sc_alternate_phone: "",

    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (onboardObject?.emergency_contact) {
            setEmployeeObject(onboardObject?.emergency_contact);
        }
    }, [onboardObject]);

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setEmployeeObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };


    const handlePhoneFormatChange = (e) => {
        const name = e.target.name;
        e.target.value = phoneNumberFormat(e.target.value)
        setEmployeeObject((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
    }

    const handlePhoneNumberKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        const keyValue = String.fromCharCode(keyCode);
        const isValidKey = /^\d$/.test(keyValue);
        const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
        const isInputValidLength = inputValue.length < 10;

        if (!isValidKey || !isInputValidLength) {
            event.preventDefault();
        }
    };

    const findFormErrors = () => {
        const { pc_fullname, pc_relation, pc_alternate_phone, pc_other, pc_phone, sc_fullname, sc_alternate_phone, sc_other, sc_phone } = employeeObject;
        const newErrors = {};
        if (!pc_fullname || pc_fullname === "") newErrors.pc_fullname = "Please enter your full name.";
        else if (pc_fullname.length < 3) {
            newErrors.pc_fullname = "The full name should be  3 to 70 characters long.";
        } else if (pc_fullname.length > 70) {
            newErrors.pc_fullname = "The full name should be  3 to 70 characters long.";
        }
        else if (!pc_fullname?.match(emptySpace)) {
            newErrors.pc_fullname = "The full name should be alphabetic.";
        }
        else if (!pc_fullname?.match(onlyCharacterWithSpace)) {
            newErrors.pc_fullname = "The full name should be alphabetic.";
        }
        if (!pc_relation || pc_relation === "") newErrors.pc_relation = "Please select relationship.";
        if (employeeObject.pc_relation === "Other") {
            if (!pc_other || pc_other === "") newErrors.pc_other = "Please enter your other relationship.";
            else if (pc_other.length < 3) {
                newErrors.pc_other = "The other relationship  should be  3 to 20 characters long.";
            } else if (pc_other.length > 20) {
                newErrors.pc_other = "The other relationship should be  3 to 20 characters long.";
            }
            else if (!pc_other?.match(onlyCharacterWithSpaceAndComma)) {
                newErrors.pc_other = "The full name should be alphabetic";
            }
        }


        if (!pc_phone || pc_phone === "" || pc_phone === null) newErrors.pc_phone = "Please enter your phone number.";

        if (pc_phone !== "") {
            const Phone_numbers_Only = pc_phone?.replace(/\D/g, '')
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.pc_phone = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.pc_phone = "Please enter a 10 digit phone number";
            }
        }
        if (pc_alternate_phone !== "" && pc_alternate_phone !== undefined) {
            const Phone_numbers_Only = pc_alternate_phone?.replace(/\D/g, '')
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.pc_alternate_phone = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.pc_alternate_phone = "Please enter a 10 digit phone number";
            }
        }

        // secondary

        if (sc_fullname !== "" && sc_fullname !== undefined) {
            if (sc_fullname.length < 3) {
                newErrors.sc_fullname = "The full name should be  3 to 70 characters long.";
            } else if (sc_fullname.length > 70) {
                newErrors.sc_fullname = "The full name should be  3 to 70 characters long.";
            }
            else if (!sc_fullname?.match(onlyCharacterWithSpace)) {
                newErrors.sc_fullname = "The full name should be alphabetic.";
            }
            else if (!sc_fullname?.match(emptySpace)) {
                newErrors.sc_fullname = "The full name should be alphabetic.";
            }
        }

        if (employeeObject.sc_relation === "Other") {
            if (!sc_other || sc_other === "") newErrors.sc_other = "Please enter your other relationship.";
            else if (sc_other.length < 3) {
                newErrors.sc_other = "The other relationship  should be  3 to 20 characters long.";
            } else if (sc_other.length > 20) {
                newErrors.sc_other = "The other relationship should be  3 to 20 characters long.";
            }
            else if (!sc_other?.match(onlyCharacterWithSpaceAndComma)) {
                newErrors.sc_other = "The full name should be alphabetic.";
            }
        }

        if (sc_phone !== "" && sc_phone !== undefined) {
            const Phone_numbers_Only = sc_phone?.replace(/\D/g, '')
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.sc_phone = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.sc_phone = "Please enter a 10 digit phone number";
            }
        }
        if (sc_alternate_phone !== "" && sc_alternate_phone !== undefined) {
            const Phone_numbers_Only = sc_alternate_phone?.replace(/\D/g, '')
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.sc_alternate_phone = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.sc_alternate_phone = "Please enter a 10 digit phone number";
            }
        }

        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            emergencyContact();
        }
    };

    const emergencyContact = async () => {
        setLoading(true);
        let data = {
            stage: "emergency_contact",
            ...employeeObject
        }
        const res = await postData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            nextStep(res.next_steps)
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };


    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <h5 className="mb-3"> Emergency contact</h5>

                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form mb-4">
                        <h4 className="emp-sub-title mb-3">Primary Contact</h4>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="pc_fullname">
                                    <Form.Label>Full name <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter full name"
                                        value={employeeObject.pc_fullname}
                                        onChange={handleInput}
                                        isInvalid={!!errors.pc_fullname}
                                        name="pc_fullname"
                                        maxLength="70"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.pc_fullname}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="pc_relation">
                                    <Form.Label>Relationship <span className="asterisk">*</span> </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="pc_relation"
                                        value={employeeObject.pc_relation}
                                        required
                                        size="lg"
                                    >
                                        <option value="">Select relationship</option>
                                        {relationshipList &&
                                            relationshipList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.pc_relation}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4} className={`${employeeObject.pc_relation === "Other" ? "d-block" : 'd-none'}`}>
                                <Form.Group className="mb-3" controlId="pc_other">
                                    <Form.Label>Other</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter other"
                                        value={employeeObject.pc_other}
                                        onChange={handleInput}
                                        isInvalid={!!errors.pc_other}
                                        name="pc_other"
                                        maxLength="70"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.pc_other}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="pc_phone">
                                    <Form.Label>Phone number  <span className="asterisk">*</span></Form.Label>
                                    <InputGroup className="phone-group">
                                        <InputGroup.Text>
                                            +1
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone number"
                                            value={employeeObject.pc_phone}
                                            onChange={handleInput}
                                            isInvalid={!!errors.pc_phone}
                                            name="pc_phone"
                                            // maxLength="10"
                                            onBlur={handlePhoneFormatChange}
                                            onKeyPress={handlePhoneNumberKeyPress}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.pc_phone}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="pc_alternate_phone">
                                    <Form.Label>Alternate Phone number </Form.Label>
                                    <InputGroup className="phone-group">
                                        <InputGroup.Text>
                                            +1
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter alternate phone number"
                                            value={employeeObject.pc_alternate_phone}
                                            onChange={handleInput}
                                            isInvalid={!!errors.pc_alternate_phone}
                                            name="pc_alternate_phone"
                                            onBlur={handlePhoneFormatChange}
                                            onKeyPress={handlePhoneNumberKeyPress}
                                        // maxLength="10"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.pc_alternate_phone}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>

                        <h4 className="emp-sub-title my-3">Secondary Contact (Optional)</h4>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="sc_fullname">
                                    <Form.Label>Full name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter full name"
                                        value={employeeObject.sc_fullname}
                                        onChange={handleInput}
                                        isInvalid={!!errors.sc_fullname}
                                        name="sc_fullname"
                                        maxLength="70"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.sc_fullname}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="sc_relation">
                                    <Form.Label>Relationship</Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="sc_relation"
                                        value={employeeObject.sc_relation}
                                        size="lg"
                                    >
                                        <option value="">Select relationship</option>
                                        {relationshipList &&
                                            relationshipList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.sc_relation}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4} className={`${employeeObject.sc_relation === "Other" ? "d-block" : 'd-none'}`}>
                                <Form.Group className="mb-3" controlId="sc_other">
                                    <Form.Label>Other</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter other relationship"
                                        value={employeeObject.sc_other}
                                        onChange={handleInput}
                                        isInvalid={!!errors.sc_other}
                                        name="sc_other"
                                        maxLength="70"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.sc_other}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="sc_phone">
                                    <Form.Label>Phone number </Form.Label>
                                    <InputGroup className="phone-group">
                                        <InputGroup.Text>
                                            +1
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone number"
                                            value={employeeObject.sc_phone}
                                            onChange={handleInput}
                                            isInvalid={!!errors.sc_phone}
                                            name="sc_phone"
                                            onBlur={handlePhoneFormatChange}
                                            onKeyPress={handlePhoneNumberKeyPress}
                                        // maxLength="10"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.sc_phone}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="sc_alternate_phone">
                                    <Form.Label>Alternate Phone number </Form.Label>
                                    <InputGroup className="phone-group">
                                        <InputGroup.Text>
                                            +1
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter alternate phone number"
                                            value={employeeObject.sc_alternate_phone}
                                            onChange={handleInput}
                                            isInvalid={!!errors.sc_alternate_phone}
                                            name="sc_alternate_phone"
                                            onBlur={handlePhoneFormatChange}
                                            onKeyPress={handlePhoneNumberKeyPress}
                                        // maxLength="10"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.sc_alternate_phone}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => nextStep('contact_info')}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div>

                    </Form>


                </div>
            )}
            {loading && <Loader />}

        </>
    )
}

export default EmergencyContact