import React from 'react';
import './style.scss'

const OnboardHeading = ({ title, subtitle }) => {
    return (
        <>
            {
                title === 'client' ?
                    <div className="board-header">
                        <div className='heading-content'>
                            <div className='head_pad'>
                                <h5> <span className='text-capitalize'>{title}</span> onboarding</h5>
                                <div className="sub-title mb-2">
                                    Please complete following onboarding forms
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    title === 'employee' ?
                        <div className="board-header">
                            <div className='heading-content'>
                                <div className='head_pad'>
                                    <h5> Onboarding form </h5>
                                    <div className="sub-title mb-2 lh-base">
                                        To ensure that you are prepared for your first day at {subtitle}, please complete the information in these forms as soon as possible. We recommend completing them prior to your first day, if possible.
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        title === "Company Details" || title === "Payroll" || title === "Signatory" || title === "Employer state tax" || title === "Profile" || title === "Sign documents" || title === "Document Details" || title === "Employee status" || title === "Complete Client Document" ?
                            <div className="board-header">
                                <div className='heading-content'>
                                    <div className='head_pad'>
                                        <h5> <span className='text-capitalize'>{title}</span></h5>
                                        <div className="sub-title mb-2">
                                            {subtitle}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="board-header">
                                <div className='heading-content'>
                                    <div className='head_pad'>
                                        <h5> Congratulations</h5>
                                        <div className="sub-title mb-2 lh-base">
                                            You have an offer to view from Siam! Please review and sign the documents below.
                                        </div>
                                    </div>
                                </div>
                            </div>
            }


        </>
    )
}

export default OnboardHeading;
