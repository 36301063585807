import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Button, Container } from "react-bootstrap";
import "./style.scss";
import { postData, getData, getRevertUser } from "../../api";
import { toast } from "react-toastify";
import OnboardHeading from "../../components/OnboardHeading";
import CompanyDetails from './CompanyDetails'
import AddAddress from "./AddAddress";
import FederalTaxSetup from "./FederalTaxSetup";
import SelectIndustry from "./SelectIndustry";
import AddBankInfo from "./AddBankInfo";
import PayrollScheme from "./PayrollScheme";
import Loader from "../../components/Loader";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { useLocation } from "react-router-dom";
import { autocloseTiming } from '../../api/regex';

const CompanySettings = () => {

    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [companySettingsObject, setCompanySettingsObject] = useState({})
    const [stateList, setStateList] = useState();
    const [taxPayerType, setTaxPayerType] = useState();
    const [taxFillingForm, setTaxFillingForm] = useState();
    const [industryType, setIndustryType] = useState();
    const [payFrequencyList, setPayFrequencyList] = useState();
    const [firstPayList, setFirstPayList] = useState();
    const [secondPayList, setSecondPayList] = useState();
    const [step, setStep] = useState();
    const companyChangeData = state;

    useEffect(() => {
        getCompanySettings();
        getStaticList();
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/company-settings') {
            document.body.classList.add('feedback-remove');
        } else {
            document.body.classList.remove('feedback-remove');
        }
    }, [window.location.pathname === '/company-settings']);


    useEffect(() => {
        if (companyChangeData !== undefined && companyChangeData !== null) {
            setStep(companyChangeData)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fragment = window.location.hash;
        if (fragment === '#add_address') {
            setStep("add_address");
        } else if (fragment === '#federal_tax') {
            setStep("federal_tax");
        } else if (fragment === '#industry') {
            setStep("industry");
        } else if (fragment === '#bank_info') {
            setStep("bank_info");
        } else if (fragment === '#payroll') {
            setStep("payroll");
        }
    }, []);

    const getCompanySettings = async () => {
        setLoading(true);
        const res = await getData("client_setting", {});
        if (res.status === true) {
            setLoading(false);
            setCompanySettingsObject(res.data)
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const getStaticList = async () => {
        setLoading(true);
        const res = await postData("get_constant", {});
        if (res.status === true) {
            setStateList(res.data.stateList)
            setTaxPayerType(res.data.taxPayerType)
            setTaxFillingForm(res.data.taxFillingForm)
            setIndustryType(res.data.industryType)
            setPayFrequencyList(res.data.payFrequencyList)
            setFirstPayList(res.data.firstPayList)
            setSecondPayList(res.data.secondPayList)
            setLoading(false);
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    }

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            {!loading && (
                <Container fluid className="new_company">
                    <Row>
                        <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                            <OnboardHeading title="Company Details" subtitle={companySettingsObject?.company_details?.company_name} />
                        </Col>
                        {
                            getRevertUser() ?
                                <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                    <div className="revert">
                                        <Button
                                            variant="danger"
                                            className="revert_btn"
                                            onClick={handleRevertUser}
                                        >
                                            <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                            Revert User
                                        </Button>
                                    </div>
                                </Col>
                                : ''
                        }
                    </Row>
                    <div className="company-settings">
                        <Accordion
                            activeKey={step}
                            onSelect={(key) => setStep(key)}
                        >
                            <Accordion.Item eventKey="company-details">
                                <Accordion.Header>Company details</Accordion.Header>
                                <Accordion.Body>
                                    <CompanyDetails companySettingsObject={companySettingsObject} stateList={stateList} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="add_address">
                                <Accordion.Header>Add addresses</Accordion.Header>
                                <Accordion.Body>
                                    <AddAddress companySettingsObject={companySettingsObject} stateList={stateList} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="federal_tax">
                                <Accordion.Header>Federal tax info</Accordion.Header>
                                <Accordion.Body>
                                    <FederalTaxSetup companySettingsObject={companySettingsObject} taxPayerType={taxPayerType} taxFillingForm={taxFillingForm} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="industry">
                                <Accordion.Header>Select industry</Accordion.Header>
                                <Accordion.Body>
                                    <SelectIndustry companySettingsObject={companySettingsObject} industryType={industryType} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="bank_info">
                                <Accordion.Header>Add bank info</Accordion.Header>
                                <Accordion.Body>
                                    <AddBankInfo companySettingsObject={companySettingsObject} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="payroll">
                                <Accordion.Header>Payroll schedule</Accordion.Header>
                                <Accordion.Body>
                                    <PayrollScheme companySettingsObject={companySettingsObject} payFrequencyList={payFrequencyList} firstPayList={firstPayList} secondPayList={secondPayList} />
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>
                </Container>
            )
            }
            {loading && <Loader />}
        </>

    );
};

export default CompanySettings;
